import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { IsOnGoing } from 'components/icons/IsOnGoing';
import { AreasTableItem } from 'models';
import { FC, useMemo } from 'react';
import classes from './AreaInformations.module.scss';

type AreaInformationsProps = FC<{
    area: AreasTableItem;
}>;

const AreaInformations: AreaInformationsProps = ({ area }) => {
    const isOnGoing = useMemo(() => {
        return area.parkingFinishTime.some(date => date > Date.now());
    }, [area.parkingFinishTime]);

    return (
        <>
            <div className={classes.infoContainer}>
                <Accordion
                    title={
                        <div className={classes.accordionTitle}>
                            <span>Informazioni</span>
                            {isOnGoing && (
                                <span className={classes.isOnGoing}>
                                    <div className={classes.icon}>
                                        <IsOnGoing />
                                    </div>
                                    <span className={classes.label}>Sosta in corso</span>
                                </span>
                            )}
                        </div>
                    }
                >
                    <AccordionTypography>
                        <div>
                            <label className="small">
                                <b>Codice</b>
                            </label>
                            <p className={classes.field}>{area.code}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Nome</b>
                            </label>
                            <p className={classes.field}>{area.name}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Città - Timezone</b>
                            </label>
                            <p className={classes.field}>
                                {area.cityName}
                                {area.timezone ? ` - ${area.timezone}` : null}
                            </p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Stalli</b>
                            </label>
                            <p className={classes.field}>{area.parkingSpaces}</p>
                        </div>
                    </AccordionTypography>
                </Accordion>
            </div>
        </>
    );
};

export default AreaInformations;

