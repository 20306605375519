import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { IconTextButton } from 'components/buttons';
import Divider from 'components/divider/Divider';
import { CheckBoxChecked, CheckBoxUnchecked, Plus } from 'components/icons';
import Snackbar from 'components/snackbar/Snackbar';
import { FC, useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, snackbarActions } from 'store';
import { useLazySimulateQuery } from 'store/rtk/simulator.service';
import { useGetVehicleTypesQuery } from 'store/rtk/vehicleType.service';
import classes from './TariffSimulator.module.scss';
import Modal from 'components/modal/Modal';
import SimulatorResultModal from './simulatorResultModal/SimulatorResultModal';
import TextButton from 'components/buttons/textButton/TextButton';
import { TariffSimulationData } from 'helpers';
import moment from 'moment';

const LABELS = {
    car: 'Autoveicoli',
    motorbike: 'Motoveicoli',
    camper: 'Camper',
    bus: 'Bus',
    electricAuto: 'Auto elettriche',
    hybrid: 'Auto ibride',
    gplAuto: 'Auto GPL'
};

const TariffSimulator: FC = () => {
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState<string>('');
    const [duration, setDuration] = useState<string>('');
    const [selectedVehicle, setSelectedVehicle] = useState<string>('car');
    const [simulationData, setSimulationData] = useState<TariffSimulationData>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { data: vehicles } = useGetVehicleTypesQuery();
    const [simulate, { isFetching }] = useLazySimulateQuery();
    const { selectedTariff } = useSelector((x: RootState) => x.tariffs);
    const { municipality } = useSelector((x: RootState) => x.municipalities);
    const dispatch = useDispatch();

    const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (value > 0 && value < 1440) {
            setDuration(e.target.value);
        } else if (Number.isNaN(value)) {
            setDuration('');
        }
    };

    const handleSimulate = useCallback(async () => {
        if (isFetching) return;
        if (!selectedTariff || !selectedVehicle || !duration || !time) {
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Seleziona una tariffa, un veicolo e un tempo di sosta.`,
                    type: 'error'
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }
        const [hours, minutes] = time.split(':').map(Number);
        const startTime = hours * 60 * 60 * 1000 + minutes * 60 * 1000;
        const newDateInMs = date.setHours(0, 0, 0, 0) + startTime;
        const res = await simulate({
            tariffId: selectedTariff?.id!,
            duration: parseInt(duration, 10),
            startTime: moment(newDateInMs)
                .tz(municipality?.timezone ?? 'Europe/Rome', true)
                .valueOf(),
            vehicleType: selectedVehicle,
            municipalityId: municipality?.id ?? ''
        });
        if (!res) {
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Errore. Controlla la connessione internet.`,
                    type: 'error'
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }
        setSimulationData(res.data);
        setIsModalOpen(true);
    }, [isFetching, selectedTariff, selectedVehicle, duration, time, date, simulate, municipality?.timezone, dispatch]);

    const mappedVehicles = useMemo(() => {
        if (!vehicles) return <></>;
        return vehicles.map(vehicle => (
            <FormControlLabel
                className={classes.vehicleForm}
                key={vehicle.type}
                checked={selectedVehicle === vehicle.type}
                control={
                    <Checkbox
                        value={vehicle.type}
                        icon={<CheckBoxUnchecked />}
                        checkedIcon={<CheckBoxChecked />}
                        onChange={() => setSelectedVehicle(vehicle.type)}
                    />
                }
                label={LABELS[vehicle.type] || vehicle.type}
            />
        ));
    }, [vehicles, selectedVehicle]);

    return (
        <div className={classes.tariffSimulatorContainer}>
            <Snackbar />
            <p>
                <b>Simulatore tariffa</b>
            </p>
            <p className={classes.title}>Sosta</p>
            <div className={classes.datePickerContainer}>
                <div className={classes.date}>
                    <label className="small">Giorno</label>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        wrapperClassName="datePicker"
                        onChange={date => date && setDate(date)}
                        selected={date}
                    />
                </div>
                <div className={classes.time}>
                    <label className="small">Orario d'inizio</label>
                    <input type="time" value={time} onChange={e => setTime(e.target.value)} className={classes.timeInput} />
                </div>
                <div className={classes.minutes}>
                    <label className="small">Minuti di sosta (max:1 giorno)</label>
                    <input
                        type="number"
                        value={duration}
                        onChange={handleMinutesChange}
                        min={1}
                        max={1439}
                        step={1}
                        placeholder="Minuti"
                        className={classes.minutesInput}
                    />
                </div>
            </div>
            <Divider customClasses={classes.divider} />
            <p className={classes.titleVehicle}>Veicolo</p>
            <p className={`small ${classes.subtitle}`}>Applica la regola a:</p>
            <FormGroup>{mappedVehicles}</FormGroup>
            <Divider customClasses={classes.divider} />
            <div className={classes.simulatorBtn}>
                <IconTextButton className={`primary`} icon={<Plus />} onClick={() => handleSimulate()}>
                    Simula
                </IconTextButton>
            </div>
            <Modal title="Simulazione" isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
                {simulationData && <SimulatorResultModal data={simulationData} />}
                <div className={classes.buttons}>
                    <TextButton onClick={() => setIsModalOpen(false)} className="primary">
                        Chiudi
                    </TextButton>
                </div>
            </Modal>
        </div>
    );
};

export default TariffSimulator;

