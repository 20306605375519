import {
    Home,
    Logout,
    Settings,
    Billing,
    BillingFilled,
    Notifications,
    NotificationsFilled,
    Chat,
    ChatFilled,
    AreaSettings,
    AreaSettingsFilled
} from 'components/icons';
import classes from './Sidebar.module.scss';
import { IconButton } from 'components/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, RootState, TabTypes, authActions, municipalityActions, tariffActions, tabActions } from 'store';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import colors from 'styles/modules/colors.module.scss';
import { customerServiceApi, useCountNewCustomerServiceRequestQuery } from 'store/rtk/customerService.service';
import { CustomerServiceStatus } from 'helpers';
import { usePermissionHandler } from 'hook';
import { UserRoles } from 'models';
import { areaApi } from 'store/rtk/area.service';
import { billingsApi } from 'store/rtk/billing.service';
import { municipalityApi } from 'store/rtk/municipality.service';
import { parkingApi } from 'store/rtk/parking.service';
import { tariffApi } from 'store/rtk/tariff.service';
import { usersApi } from 'store/rtk/user.service';
import { vehicleTypeApi } from 'store/rtk/vehicleType.service';

const Sidebar: FC = () => {
    const [time, setTime] = useState<number>(0);
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const { activeTab } = useSelector((x: RootState) => x.tab);
    const { customerServiceItems } = useSelector((x: RootState) => x.customerService);
    const dispatch = useDispatch();
    const { data } = useCountNewCustomerServiceRequestQuery(time);
    const hasPermission = usePermissionHandler();

    const customerServiceIcon = useMemo(() => {
        if (!data) return <Chat />;
        const isCustomerServiceTab = activeTab === TabTypes.customerService;
        const notifications =
            customerServiceItems.length > 0 &&
            customerServiceItems.filter(({ status }) => status === CustomerServiceStatus.notRead).length === 0;
        const hasData = data.numberOfRequest > 0 && !notifications;
        if (isCustomerServiceTab) return hasData ? <NotificationsFilled /> : <ChatFilled />;
        else return hasData ? <Notifications /> : <Chat />;
    }, [activeTab, customerServiceItems, data]);

    useEffect(() => {
        const ref = setInterval(() => setTime(Date.now()), 60000);
        return () => clearInterval(ref);
    }, [setTime]);

    useEffect(() => {
        if (!user?.role) return;
        if (user.role === UserRoles.configurator) dispatch(tabActions.setActiveTab(TabTypes.municipalitySettings));
    }, [dispatch, user]);

    const switchTabHandler = useCallback(
        (newTab: TabTypes) => {
            dispatch(tabActions.setActiveTab(newTab));
            if (activeTab === TabTypes.municipalitySettings) {
                dispatch(municipalityActions.resetSlice());
                dispatch(tariffActions.resetSlice());
            }
        },
        [activeTab, dispatch]
    );

    const onLogoutHandler = () => {
        dispatch(tabActions.setActiveTab(TabTypes.home));
        dispatch(areaApi.util.resetApiState());
        dispatch(billingsApi.util.resetApiState());
        dispatch(customerServiceApi.util.resetApiState());
        dispatch(municipalityApi.util.resetApiState());
        dispatch(parkingApi.util.resetApiState());
        dispatch(tariffApi.util.resetApiState());
        dispatch(usersApi.util.resetApiState());
        dispatch(vehicleTypeApi.util.resetApiState());
        dispatch(authActions.logout());
        dispatch(municipalityActions.resetSlice());
        dispatch(tariffActions.resetSlice());
    };

    return (
        <div className={classes.sidebar}>
            {hasPermission(TabTypes.home, ['can-view']) && (
                <IconButton
                    className="tertiary"
                    icon={<Home fill={activeTab === TabTypes.home ? colors.secondary50 : undefined} />}
                    onClick={() => switchTabHandler(TabTypes.home)}
                />
            )}
            {hasPermission(TabTypes.billing, ['can-view']) && (
                <IconButton
                    className="tertiary"
                    icon={activeTab === TabTypes.billing ? <BillingFilled /> : <Billing />}
                    onClick={() => switchTabHandler(TabTypes.billing)}
                />
            )}
            {hasPermission(TabTypes.customerService, ['can-view']) && (
                <IconButton
                    className="tertiary"
                    icon={customerServiceIcon}
                    onClick={() => switchTabHandler(TabTypes.customerService)}
                />
            )}
            {hasPermission(TabTypes.municipalitySettings, ['can-view']) && (
                <IconButton
                    className="tertiary"
                    icon={activeTab === TabTypes.municipalitySettings ? <AreaSettingsFilled /> : <AreaSettings />}
                    onClick={() => switchTabHandler(TabTypes.municipalitySettings)}
                />
            )}
            {hasPermission(TabTypes.settings, ['can-view']) && (
                <IconButton
                    className="tertiary"
                    icon={<Settings fill={activeTab === TabTypes.settings ? colors.secondary50 : undefined} />}
                    onClick={() => switchTabHandler(TabTypes.settings)}
                />
            )}
            <div className={classes.logout}>
                <IconButton className="tertiary" icon={<Logout />} onClick={onLogoutHandler} />
            </div>
        </div>
    );
};

export default Sidebar;

