import { FC, useCallback, useEffect, useState } from 'react';
import CustomerServiceDetails from './customerServiceDetails/CustomerServiceDetails';
import classes from './CustomerService.module.scss';
import CustomerServiceItems from './customerServiceItems/CustomerServiceItems';
import {
    CustomerServiceItem,
    DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES,
    DEFAULT_FAKE_CUSTOMER_SERVICE,
    applyFilters
} from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, customerServiceActions } from 'store';
import CustomerServiceFilters from 'views/backoffice/customerService/customerServiceFilters/CustomerServiceFilters';
import { useInView } from 'react-intersection-observer';
import { useGetAllCustomerServiceQuery } from 'store/rtk/customerService.service';

const CustomerService: FC = () => {
    const [activeFilters, setActiveFilters] = useState(DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES);
    const [pages, setPages] = useState<number>(0);
    const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);
    const [searchedWord, setSearchedWord] = useState<string>('');
    const { customerServiceItems } = useSelector((x: RootState) => x.customerService);
    const dispatch = useDispatch();
    const { ref, inView } = useInView({
        threshold: 0
    });
    const { data, refetch } = useGetAllCustomerServiceQuery({
        offset: pages * 20
    });
    const [filteredCustomerServices, setFilteredCustomerServices] = useState<CustomerServiceItem[]>([]);

    useEffect(() => {
        if (!inView) return;
        if (!data || data.length === 0) return;
        setPages(pages + 1);
    }, [inView, customerServiceItems]);

    useEffect(() => {
        if (!data || data.length === 0) return;
        dispatch(customerServiceActions.setCustomerServiceItems([...customerServiceItems, ...data]));
        setFilteredCustomerServices(prev => {
            return [...prev, ...applyFilters(data, activeFilters, searchedWord)].reduce(
                (acc, cur) => (acc.find(a => cur.id === a.id) ? acc : [...acc, cur]),
                [] as CustomerServiceItem[]
            );
        });
    }, [data]);

    const onInputChangeHandler = useCallback(
        (value: string) => {
            setSearchedWord(value);
            setFilteredCustomerServices(() => applyFilters(customerServiceItems, activeFilters, value));
        },
        [activeFilters, customerServiceItems]
    );

    const onFiltersChangeHandler = useCallback(
        (key: any, value: any) => {
            if (key === 'date') {
                const dateFilters = { ...activeFilters, date: { ...activeFilters.date, ...value } };
                setActiveFilters((prev: any) => ({ ...prev, date: { ...prev.date, ...value } }));
                setFilteredCustomerServices(() => applyFilters(customerServiceItems, dateFilters, searchedWord));
                return;
            }
            const filters = { ...activeFilters, [key]: value };
            setActiveFilters((prev: any) => ({ ...prev, [key]: value }));
            setFilteredCustomerServices(() => applyFilters(customerServiceItems, filters, searchedWord));
        },
        [activeFilters, customerServiceItems, searchedWord]
    );

    const resetFilters = useCallback(() => {
        setFilteredCustomerServices(() => applyFilters(customerServiceItems, DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES, ''));
        setActiveFilters(DEFAULT_CUSTOMER_SERVICES_FILTER_VALUES);
    }, [customerServiceItems]);

    return (
        <div className={classes.container}>
            <CustomerServiceItems
                setFilteredCustomerServices={setFilteredCustomerServices}
                setFiltersOpen={setAreFiltersOpen}
                activeFilters={activeFilters}
                setSearchedWord={onInputChangeHandler}
                searchedWord={searchedWord}
                items={
                    filteredCustomerServices.length > 0
                        ? [...filteredCustomerServices, DEFAULT_FAKE_CUSTOMER_SERVICE]
                        : [DEFAULT_FAKE_CUSTOMER_SERVICE]
                }
                ref={ref}
            />
            {areFiltersOpen ? (
                <div className={classes.filtersContainer}>
                    <CustomerServiceFilters
                        activeFilters={activeFilters}
                        setActiveFilters={onFiltersChangeHandler}
                        resetFilters={resetFilters}
                        setFiltersOpen={setAreFiltersOpen}
                    />
                </div>
            ) : (
                <CustomerServiceDetails
                    setFilteredCustomerServices={setFilteredCustomerServices}
                    areFiltersOpen={areFiltersOpen}
                />
            )}
        </div>
    );
};

export default CustomerService;

