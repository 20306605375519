import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import Accordion from 'components/accordion/Accordion';
import Chip from 'components/chip/Chip';
import GridTable from 'components/gridTable/GridTable';
import { AttentionCircle, BillingFilledSmall, CheckmarkChecked, CircleRefound, RadioUnchecked } from 'components/icons';
import { formatDate } from 'helpers';
import { BillingDetailsData, BillingStatus, OrderStatus } from 'models';
import { FC, useState } from 'react';
import classes from './AccordionTable.module.scss';
import moment from 'moment';

type AccordionTableProps = FC<{
    dataToMap: BillingDetailsData;
}>;

const getPaymentStatusIcon = (paymentStatus: OrderStatus) => {
    if (paymentStatus === OrderStatus.paid) return <CheckmarkChecked />;
    if (paymentStatus === OrderStatus.created) return <RadioUnchecked fill="white" />;
    if (paymentStatus === OrderStatus.refunded) return <CircleRefound />;
    if (paymentStatus === OrderStatus.canceled) return <AttentionCircle />;
    return <></>;
};

const getBillingStatusIcon = (billingStatus: BillingStatus) => {
    if (billingStatus === BillingStatus.issued) return <CheckmarkChecked />;
    if (billingStatus === BillingStatus.pending) return <RadioUnchecked fill="white" />;
    return <></>;
};

export const BILLINGS_DETAILS_HEADERS: GridColDef[] = [
    {
        field: 'area_code',
        width: 60,
        headerName: 'Codice',
        valueGetter: props => (props.row.area_code ? props.row.area_code : '***')
    },
    {
        field: 'area_name',
        width: 150,
        headerName: 'Nome',
        valueGetter: props => (props.row.area_name ? props.row.area_name : '***')
    },
    {
        field: 'municipality_name',
        width: 100,
        headerName: 'Città',
        valueGetter: props => (props.row.municipality_name ? props.row.municipality_name : '***')
    },
    { field: 'plate', width: 80, headerName: 'Targa' },
    { field: 'amount', width: 80, headerName: 'Spesa' },
    {
        field: 'payment_status',
        width: 60,
        headerName: 'Stato',
        renderCell: props => getPaymentStatusIcon(props.row.payment_status)
    },
    {
        field: 'billing_status',
        width: 70,
        headerName: 'Fattura',
        renderCell: props => getBillingStatusIcon(props.row.billing_status)
    },
    { field: 'payment_time', width: 150, headerName: 'Pagato il' }
];

const AccordionTable: AccordionTableProps = ({ dataToMap }) => {
    const [paginationModel, setPaginationModel] = useState({ pageSize: 20, page: 0 });

    return (
        <div className={classes.container}>
            {dataToMap?.usersBillings?.map((userBilling, i) => (
                <Accordion
                    key={userBilling.id + i}
                    title={
                        <span className={classes.title}>
                            {userBilling.first_name + ' ' + userBilling.last_name}
                            <Chip
                                key={userBilling.business_name + i}
                                icon={<BillingFilledSmall />}
                                label={userBilling.userBillings.length.toString()}
                                active={userBilling.userBillings.some(bill => bill.billing_status === 'pending')}
                            />
                        </span>
                    }
                >
                    <Typography>
                        <GridTable
                            rowCount={userBilling.userBillings.length}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            headers={BILLINGS_DETAILS_HEADERS}
                            sortingMode="client"
                            rows={userBilling.userBillings.map(userBilling => ({
                                id: userBilling.id,
                                area_code: userBilling.area_code,
                                area_name: userBilling.area_name,
                                municipality_name: userBilling.municipality_name,
                                plate: userBilling.plate ? userBilling.plate : 'N/A',
                                amount: `${userBilling.amount.toFixed(2).replace('.', ',')} €`,
                                payment_status: userBilling.payment_status,
                                billing_status: userBilling.billing_status,
                                payment_time:
                                    userBilling.payment_time &&
                                    formatDate(moment(userBilling.payment_time).tz(userBilling.timezone))
                            }))}
                        />
                    </Typography>
                </Accordion>
            ))}
        </div>
    );
};

export default AccordionTable;

