import { IconButton, IconTextButton } from 'components/buttons';
import Dashboard from 'components/dashboard/Dashboard';
import { ArrowBack } from 'components/icons';
import { Edit } from 'components/icons/Edit';
import { DEFAULT_LAYER_DATA, DashboardProps, formatFeature } from 'helpers';
import { usePermissionHandler } from 'hook';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MunicipalityTableTypes,
    RootState,
    TabTypes,
    areasActions,
    drawActions,
    municipalityActions,
    snackbarActions,
    tabActions
} from 'store';
import { useGetAreaDetailDashboardInfoQuery, useLazyGetAreaByIdQuery } from 'store/rtk/area.service';
import classes from './AreaDetail.module.scss';
import AreaInformations from './areaInformations/AreaInformations';
import AreaTariffs from './areaTariffs/AreaTariffs';
import { AreasTableItem } from 'models';
import Snackbar from 'components/snackbar/Snackbar';

const AreaDetail: FC = () => {
    const { selectedArea } = useSelector((x: RootState) => x.areas);
    const { data } = useGetAreaDetailDashboardInfoQuery({ id: selectedArea?.id! });
    const [getAreaById] = useLazyGetAreaByIdQuery();
    const hasPermission = usePermissionHandler();
    const dispatch = useDispatch();

    const dashboardValues: DashboardProps[] = useMemo(
        () => [
            { label: 'Stalli', value: data?.parkingSpaces! },
            { label: 'Soste', value: data?.totalParking! },
            { label: 'Incasso Totale', value: `${data?.totalAmount?.toFixed(2).replace('.', ',') ?? 0}€` }
        ],
        [data]
    );

    const onEditHandler = useCallback(async () => {
        if (!selectedArea) return;
        let area: AreasTableItem = selectedArea;
        try {
            area = await getAreaById({ id: selectedArea.id }).unwrap();
        } catch (err) {
            dispatch(snackbarActions.setMessageAndType({ message: `Errore nella modifica dell'area.`, type: 'error' }));
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }
        dispatch(tabActions.setActiveTab(TabTypes.municipalitySettings));
        dispatch(municipalityActions.setSelectedTable(MunicipalityTableTypes.areas));
        dispatch(municipalityActions.setSelectedArea({ ...area, code: area.code.toString(), street: area.street } as any));
        dispatch(drawActions.setData({ ...DEFAULT_LAYER_DATA, features: [formatFeature(area.geometry)] }));
        dispatch(drawActions.zoomOnBbox(area.geometry));
        dispatch(municipalityActions.toggleEditArea());
        dispatch(drawActions.setMode('modify'));
    }, [dispatch, getAreaById, selectedArea]);

    return (
        <>
            <div className={classes.dashboard}>
                <Dashboard props={dashboardValues} />
            </div>
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.backButton}>
                        <IconButton
                            className="tertiary"
                            icon={<ArrowBack />}
                            onClick={() => dispatch(areasActions.setSelectedArea(undefined))}
                        />
                        <p>
                            <b>
                                {selectedArea?.code} - {selectedArea?.name}
                            </b>
                        </p>
                    </div>
                    <IconTextButton
                        disabled={!hasPermission(TabTypes.home, ['can-edit'])}
                        className="secondary"
                        onClick={onEditHandler}
                        icon={<Edit />}
                    >
                        Modifica
                    </IconTextButton>
                </div>
                <AreaInformations area={selectedArea!} />
                <AreaTariffs tariffName={selectedArea?.tariffName!} />
                <Snackbar />
            </div>
        </>
    );
};

export default AreaDetail;

