import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_SLOPE_VALUE } from 'helpers';

export interface CreateTariffRuleState {
    name: string;
    type: string;
    alwaysValid: boolean;
    allYears: boolean;
    isRepetitionModalOpen: boolean;
    validityPeriod: {
        repetitionType: string;
        period: { startTime: string; endTime: string };
        slots: {
            period: { startDate?: number; endDate?: number };
            day: { day?: number; month?: number; year?: number };
            weekDay: {
                textDay?: number;
                weekNumber?: number;
                month?: number;
                year?: number;
            };
            preset: { beforeHoliday?: boolean; holiday?: boolean; year?: number };
            dayType?: string;
            type: string;
        }[];
    };
    vehicleIds: string[];
    freeTime: { days?: number; hours?: number; mins?: number };
    minTime: { amount?: number; days?: number; hours?: number; mins?: number };
    weekly: string[];
    slopes: any[];
    isSubmitted: boolean;
    isValid: Record<string, boolean>;
}

export const DEFAULT_VALIDITY_SLOT = {
    period: { startDate: undefined, endDate: undefined },
    day: { day: undefined, month: undefined, year: undefined },
    weekDay: {
        textDay: undefined,
        weekNumber: undefined,
        month: undefined,
        year: undefined
    },
    preset: { beforeHoliday: undefined, holiday: undefined, year: undefined },
    dayType: 'day',
    type: ''
};

const name = 'createTariffRule';
const initialState: CreateTariffRuleState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const createTariffRuleActions = { ...slice.actions };
export const createTariffRuleReducer = slice.reducer;

function createInitialState(): CreateTariffRuleState {
    return {
        name: '',
        type: '',
        alwaysValid: false,
        allYears: true,
        isRepetitionModalOpen: false,
        validityPeriod: {
            repetitionType: 'always',
            period: { startTime: '', endTime: '' },
            slots: [DEFAULT_VALIDITY_SLOT]
        },
        vehicleIds: [],
        freeTime: { days: undefined, hours: undefined, mins: undefined },
        minTime: { amount: undefined, days: undefined, hours: undefined, mins: undefined },
        weekly: [],
        slopes: [],
        isSubmitted: false,
        isValid: {}
    };
}

function createReducers() {
    return {
        setRuleName,
        setSubmit,
        setIsValid,
        setRuleType,
        setSlopes,
        setRuleVehicles,
        toggleAlwayValid,
        toggleAllYears,
        setIsRepetitionModalOpen,
        validityPeriodSlotsHandler,
        setValidityPeriodValues,
        setValidityPeriodSlot,
        setDay,
        setWeekDay,
        toggleRuleVehicles,
        setFreeTime,
        setMinTime,
        setWeekly,
        addSlope,
        removeSlope,
        sortSlope,
        updateSlope,
        clearSlopeField,
        resetCreation
    };

    function setRuleName(state: CreateTariffRuleState, action: PayloadAction<string>) {
        state.name = action.payload;
    }

    function setSubmit(state: CreateTariffRuleState, action: PayloadAction<boolean>) {
        state.isSubmitted = action.payload;
    }
    function setIsValid(state: CreateTariffRuleState, action: PayloadAction<{ key: string; isValid: boolean }>) {
        state.isValid[action.payload.key] = action.payload.isValid;
    }

    function setRuleType(state: CreateTariffRuleState, action: PayloadAction<string>) {
        state.type = action.payload;
    }

    function setSlopes(state: CreateTariffRuleState, action: PayloadAction<any[]>) {
        state.slopes = action.payload;
    }

    function toggleAlwayValid(state: CreateTariffRuleState, action: PayloadAction<boolean | undefined>) {
        state.alwaysValid = action.payload ?? !state.alwaysValid;
    }

    function toggleAllYears(state: CreateTariffRuleState, action: PayloadAction<boolean | undefined>) {
        state.allYears = action.payload ?? !state.allYears;
    }

    function setIsRepetitionModalOpen(state: CreateTariffRuleState, action: PayloadAction<boolean>) {
        state.isRepetitionModalOpen = action.payload;
    }
    function setRuleVehicles(state: CreateTariffRuleState, action: PayloadAction<string[]>) {
        state.vehicleIds = action.payload;
    }

    function validityPeriodSlotsHandler(
        state: CreateTariffRuleState,
        action: PayloadAction<{ actionType: string; index: number }>
    ) {
        const { actionType, index } = action.payload;
        if (actionType === 'add') state.validityPeriod.slots.push(DEFAULT_VALIDITY_SLOT);
        else {
            state.validityPeriod.slots.splice(index, 1);
            delete state.isValid[`validitySlot-${index}`];
        }
    }

    function setValidityPeriodValues(state: CreateTariffRuleState, action: PayloadAction<{ key: any; value: any }>) {
        const { key, value } = action.payload;
        state.validityPeriod = { ...state.validityPeriod, [key]: value };
    }

    function setValidityPeriodSlot(
        state: CreateTariffRuleState,
        action: PayloadAction<{ key: string; value: any; index: number }>
    ) {
        const { index, value, key } = action.payload;
        if (key === 'type') state.validityPeriod.slots[index] = { ...DEFAULT_VALIDITY_SLOT, type: value };
        else if (key === 'dayType')
            state.validityPeriod.slots[index] = {
                ...DEFAULT_VALIDITY_SLOT,
                [key]: value,
                type: state.validityPeriod.slots[index].type
            };
        else state.validityPeriod.slots[index] = { ...state.validityPeriod.slots[index], [key]: value };
    }

    function setDay(state: CreateTariffRuleState, action: PayloadAction<{ key: any; value: any; index: number }>) {
        const { key, value, index } = action.payload;
        state.validityPeriod.slots[index].day = { ...state.validityPeriod.slots[index].day, [key]: value };
    }

    function setWeekDay(state: CreateTariffRuleState, action: PayloadAction<{ key: any; value: any; index: number }>) {
        const { key, value, index } = action.payload;
        state.validityPeriod.slots[index].weekDay = { ...state.validityPeriod.slots[index].weekDay, [key]: value };
    }
    function toggleRuleVehicles(state: CreateTariffRuleState, action: PayloadAction<string>) {
        if (!state.vehicleIds.includes(action.payload)) {
            state.vehicleIds.push(action.payload);
            return;
        }
        const index = state.vehicleIds.findIndex(vehicle => vehicle === action.payload);
        if (index < 0) return;
        state.vehicleIds.splice(index, 1);
    }

    function setFreeTime(state: CreateTariffRuleState, action: PayloadAction<{ key: any; value?: number }>) {
        state.freeTime = { ...state.freeTime, [action.payload.key]: action.payload.value };
    }

    function setMinTime(state: CreateTariffRuleState, action: PayloadAction<{ key: any; value?: number }>) {
        state.minTime = { ...state.minTime, [action.payload.key]: action.payload.value };
    }

    function setWeekly(state: CreateTariffRuleState, action: PayloadAction<string[]>) {
        state.weekly = action.payload;
    }

    function addSlope(state: CreateTariffRuleState) {
        const slopes = [...state.slopes, DEFAULT_SLOPE_VALUE];
        state.slopes = slopes;
    }

    function removeSlope(state: CreateTariffRuleState, action: PayloadAction<number>) {
        const slopes = [...state.slopes];
        if (action.payload < 0) return;
        slopes.splice(action.payload, 1);
        state.slopes = slopes;
    }

    function sortSlope(state: CreateTariffRuleState, action: PayloadAction<{ index: number; offset: number }>) {
        const slopes = [...state.slopes];
        const { index, offset } = action.payload;
        if (index < 0) return;
        const [slope] = slopes.splice(index, 1);
        slopes.splice(index + offset, 0, slope);
        state.slopes = slopes;
    }

    function updateSlope(state: CreateTariffRuleState, action: PayloadAction<{ index: number; key: string; value: any }>) {
        const slopes = [...state.slopes];
        const { index, key, value } = action.payload;
        if (key === 'slopeType') slopes[index] = { ...DEFAULT_SLOPE_VALUE, [key]: value };
        slopes[index] = { ...slopes[index], [key]: value };
        state.slopes = slopes;
    }

    function clearSlopeField(state: CreateTariffRuleState, action: PayloadAction<{ keys: string[]; index: number }>) {
        const slopes = [...state.slopes];
        const { index, keys } = action.payload;
        keys.forEach(key => (slopes[index] = { ...slopes[index], [key]: '' }));
        state.slopes = slopes;
    }

    function resetCreation() {
        return createInitialState();
    }
}

