import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip, TextField, Checkbox } from '@mui/material';
import classes from './MunicipalitiesSelect.module.scss';
import { ArrowDown, CheckBoxChecked, CheckBoxUnchecked, Close, Search } from 'components/icons';
import { useGetAllMunicipalitiesQuery } from 'store/rtk/municipality.service';
import { useDispatch } from 'react-redux';
import { municipalityActions } from 'store';

type MunicipalitiesSelectProps = FC<{
    onMunicipalitiesSelected: (municipalities: string[]) => void;
    municipalitiesId?: string[];
}>;

const MunicipalitiesSelect: MunicipalitiesSelectProps = ({ onMunicipalitiesSelected, municipalitiesId }) => {
    const { data: municipalities, refetch } = useGetAllMunicipalitiesQuery();
    const [values, setValues] = useState<{ value: string; label: string }[]>([]);
    const [options, setOptions] = useState<{ value: string; label: string }[]>([{ value: '', label: '' }]);
    const dispatch = useDispatch();

    useEffect(() => {
        municipalities && dispatch(municipalityActions.setMunicipalities(municipalities));
    }, [municipalities, dispatch]);

    useEffect(() => {
        if (municipalities?.length) {
            const options = municipalities.map(municipality => ({ label: municipality.name, value: municipality.id }));
            options.sort((a, b) => a.label.localeCompare(b.label));
            setOptions(options);
        }
    }, [municipalities]);

    useEffect(() => {
        municipalitiesId?.forEach(id => {
            const option = options.find(option => option.value === id);
            if (!option) return;
            setValues(prev => [...prev, { label: option.label, value: id }]);
        });
    }, [options, municipalitiesId]);

    const onChangeHandler = useCallback(
        (newValues: any) => {
            onMunicipalitiesSelected(newValues);
            setValues(newValues);
        },
        [onMunicipalitiesSelected]
    );

    const dynamicPlaceholder = useMemo(() => {
        if (values.length === 1) return `${values[0].label}`;
        if (values.length > 1) return `(${values.length}) comuni selezionati`;
        return 'Digita Comune';
    }, [values]);

    return (
        <>
            <p className="small lightBlue">
                <b>Comuni</b>
            </p>
            <Autocomplete
                popupIcon={<ArrowDown />}
                disableCloseOnSelect
                clearIcon={<Close />}
                ListboxProps={{
                    style: {
                        maxHeight: '205px'
                    }
                }}
                multiple
                isOptionEqualToValue={(option, value) => value.value === option.value}
                options={options}
                value={values}
                className={classes.autocomplete}
                renderGroup={(...props) => {
                    return (
                        <>
                            <div className={classes.capitalGroup}>{props[0].group}</div>
                            {props[0].children}
                        </>
                    );
                }}
                filterOptions={(value, state) =>
                    value.filter(v => v.label.toLowerCase().includes(state.inputValue.toLowerCase()))
                }
                groupBy={option => option.label[0]?.toUpperCase()}
                onChange={(_event, newValue) => onChangeHandler(newValue)}
                onOpen={refetch}
                renderOption={(props, option, { selected }) => (
                    <li {...props} className={classes.list}>
                        <Checkbox
                            icon={<CheckBoxUnchecked />}
                            checkedIcon={<CheckBoxChecked />}
                            checked={values.map(v => v.value).includes(option.value)}
                            className={classes.checkBoxStyle}
                        />
                        {option.label}
                    </li>
                )}
                renderInput={params => (
                    <TextField
                        className={classes.chipDefault}
                        {...params}
                        placeholder={dynamicPlaceholder}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <div className={classes.search}>
                                        <Search />
                                        {params.InputProps.startAdornment}
                                    </div>
                                </>
                            )
                        }}
                    />
                )}
                renderTags={() => <></>}
                PaperComponent={({ children }) => (
                    <div className={classes.optionsList}>
                        <div className={classes.chips}>
                            {values?.length > 0 && values.map((option, index) => <Chip key={index} label={option.label} />)}
                        </div>
                        {children}
                    </div>
                )}
            />
        </>
    );
};

export default MunicipalitiesSelect;

