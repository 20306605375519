import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import PeriodOfTimeFilter from 'components/periodOfTimeFilter/PeriodOfTimeFilter';
import { AreaDateFilter } from 'helpers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTariffRuleState, RootState, createTariffRuleActions } from 'store';
import classes from './PeriodModal.module.scss';

type DatePicker = Partial<AreaDateFilter>;

const TEXT_FIRST_PICKER = {
    label: 'Dal giorno',
    placeholder: 'Seleziona'
};
const TEXT_SECOND_PICKER = {
    label: 'Al giorno',
    placeholder: 'Seleziona'
};

type PeriodModalProps = FC<{
    index: number;
    isOpen: boolean;
    onClose: (type: string) => void;
}>;

const PeriodModal: PeriodModalProps = ({ index, isOpen, onClose }) => {
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const { validityPeriod } = useSelector((x: RootState) => x.createTariffRule);
    const [date, setDate] = useState<DatePicker>({ startDate: undefined, endDate: undefined });
    const { allYears } = useSelector<RootState>(x => x.createTariffRule) as CreateTariffRuleState;
    const dispatch = useDispatch();

    const onDateChangeHandler = (_key: string, value: AreaDateFilter) => setDate(prev => ({ ...prev, ...value }));
    const onSaveHandler = useCallback(() => {
        dispatch(createTariffRuleActions.setValidityPeriodSlot({ index, key: 'type', value: 'period' }));
        dispatch(createTariffRuleActions.setValidityPeriodSlot({ key: 'period', index, value: date }));
        onClose('period');
    }, [date, dispatch, index, onClose]);

    const isModalDisabled = useMemo(
        () => date.endDate === undefined || date.startDate === undefined || date.startDate >= date.endDate,
        [date.endDate, date.startDate]
    );

    useEffect(() => {
        if (!isOpen) return;
        const newDate = {
            startDate: new Date(validityPeriod.slots[index].period.startDate!).getTime(),
            endDate: new Date(validityPeriod.slots[index].period.endDate!).getTime()
        };
        setDate(newDate);
    }, [index, isOpen, validityPeriod.slots]);

    useEffect(() => {
        if (!selectedRule || !selectedRule.ValidityPeriods[index]) return;
        const newDate = {
            startDate: new Date(selectedRule.ValidityPeriods[index].startDay).getTime(),
            endDate: new Date(selectedRule.ValidityPeriods[index].endDay).getTime()
        };
        setDate(newDate);
    }, [index, selectedRule]);

    return (
        <Modal isOpen={isOpen} handleClose={() => onClose('')} title="Periodo">
            <PeriodOfTimeFilter
                customClasses={classes.filterStyle}
                activeFilter={date as AreaDateFilter}
                setActiveFilter={onDateChangeHandler}
                customTextFirstPicker={TEXT_FIRST_PICKER}
                customTextSecondPicker={TEXT_SECOND_PICKER}
            />
            {allYears && <label className={`lightBlue ${classes.label}`}>Il periodo verrà ripetuto tutti gli anni</label>}
            <div className={classes.buttons}>
                <TextButton onClick={() => onClose('')} className={`secondary ${classes.btn}`}>
                    Annulla
                </TextButton>
                <TextButton onClick={onSaveHandler} className={`primary ${classes.btn}`} disabled={isModalDisabled}>
                    Salva
                </TextButton>
            </div>
        </Modal>
    );
};

export default PeriodModal;

