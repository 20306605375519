import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { ParkingDetail } from 'helpers';
import { Billing, PaymentInfo, TicketDashboard } from 'models';
import { store } from 'store';

interface GetAllParkingParams {
    city: string | null;
    date: string;
    vehicle: string;
    userIdFilter: string;
    paymentState: string;
    billingState: string;
    paymentValueRange: string;
    searchedWord: string;
    inProgress: string;
    orderBy?: string;
}

interface DashboardInfo {
    totalParking: string;
    finishedParking: string;
    totalTime: { hours: number; minutes: number };
    totalAmount: number;
}

interface DashboardDetailInfo {
    totalTime: number;
    amount: number;
    fees: number;
}

export const parkingApi = createApi({
    reducerPath: 'parking-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getParkingDashboardInfo: builder.query<DashboardInfo, GetAllParkingParams>({
            query: params => ({
                url: '/tickets/dashboard-info',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        getParkingDetailDashboardInfo: builder.query<DashboardDetailInfo, { id: string }>({
            query: params => ({
                url: '/tickets/dashboard-detail-info',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        getAllParking: builder.query<TicketDashboard[], GetAllParkingParams & { offset: number; pageSize: number }>({
            query: params => ({
                url: '/tickets',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        getParkingDetail: builder.query<
            { ticket: ParkingDetail; companyInformations: Billing; paymentInfo: PaymentInfo },
            { id: string }
        >({
            query: params => ({
                url: '/tickets/detail',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        getPendingTickets: builder.query<ParkingDetail[], any>({
            query: params => ({
                url: '/tickets/pending/by-area',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        paymentRefund: builder.query<any, { ticketId: string }>({
            query: body => ({
                url: '/orders',
                method: 'POST',
                body
            }),
            keepUnusedDataFor: 1
        })
    })
});

export const {
    useGetAllParkingQuery,
    useGetParkingDetailDashboardInfoQuery,
    useGetParkingDetailQuery,
    useGetParkingDashboardInfoQuery,
    useLazyGetPendingTicketsQuery,
    useLazyPaymentRefundQuery
} = parkingApi;

