import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PricingTime from '../../pricingTime/PricingTime';
import RadioButton from 'components/radioButton/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions } from 'store';
import { Proportional } from 'components/icons';
import { isStringEmptyOrZero } from 'helpers';

const PROPORTIONAL_PRICING_TIME_OPTIONS = [
    { label: 'Giorni', key: 'days', type: 'number', validate: (slope: any) => slope.days > 0 && slope.days < 999 },
    { label: 'Ore', key: 'hours', type: 'number', validate: (slope: any) => slope.hours > 0 && slope.hours < 24 },
    { label: 'Minuti', key: 'minutes', type: 'number', validate: (slope: any) => slope.minutes > 0 && slope.minutes < 60 }
];

const MAX_TYPE_OPTIONS = [
    { value: 'time', label: 'Tempo Massimo' },
    { value: 'price', label: 'Costo massimo' }
];

const MAX_TIME_OPTIONS = [
    { label: 'Giorni', key: 'maxDays', type: 'number', validate: (slope: any) => slope.maxDays > 0 && slope.maxDays < 999 },
    { label: 'Ore', key: 'maxHours', type: 'number', validate: (slope: any) => slope.maxHours > 0 && slope.maxHours < 24 },
    {
        label: 'Minuti',
        key: 'maxMinutes',
        type: 'number',
        validate: (slope: any) => slope.maxMinutes > 0 && slope.maxMinutes < 60
    }
];

type ProportionalPricingProps = FC<{
    slope: any;
    onInputChange: (key: string, value: any) => void;
    index: number;
}>;

const ProportionalPricing: ProportionalPricingProps = ({ onInputChange, slope, index }) => {
    const [maxType, setMaxType] = useState('time');
    const { isSubmitted } = useSelector((x: RootState) => x.createTariffRule);
    const dispatch = useDispatch();

    const leftRef = document.getElementById(`left-slope-${index}`);
    const rightRef = document.getElementById(`right-slope-${index}`);
    const headerRef = document.getElementById(`header-slope-${index}`);

    const tariffs = useMemo(
        () => (
            <div>
                {!isStringEmptyOrZero(slope.days) ? slope.days + 'g ' : ''}
                {!isStringEmptyOrZero(slope.hours) ? slope.hours + 'h ' : ''}
                {!isStringEmptyOrZero(slope.minutes) ? slope.minutes + 'm' : ''}&nbsp;
            </div>
        ),
        [slope]
    );

    const maxTariffs = useMemo(() => {
        if (slope.price) return <div>{', max ' + slope.price + '€'}</div>;
        if (slope.maxDays === '' && slope.maxHours === '' && slope.maxMinutes === '') return <></>;
        return (
            <div>
                {', max '}
                {!isStringEmptyOrZero(slope.maxDays) ? slope.maxDays + 'g ' : ''}
                {!isStringEmptyOrZero(slope.maxHours) ? slope.maxHours + 'h ' : ''}
                {!isStringEmptyOrZero(slope.maxMinutes) ? slope.maxMinutes + 'm' : ''}
                {!isStringEmptyOrZero(slope.price) ? slope.price + '€' : ''}
            </div>
        );
    }, [slope]);

    const proportionalPricingTimeOptions = useMemo(() => {
        const isValid = PROPORTIONAL_PRICING_TIME_OPTIONS.some(opt => opt.validate(slope));
        return PROPORTIONAL_PRICING_TIME_OPTIONS.map((opt: any) => {
            opt.isValid = !isSubmitted || isValid;
            return opt;
        });
    }, [isSubmitted, slope]);

    const maxTimeOptions = useMemo(() => {
        const isValid = MAX_TIME_OPTIONS.some(opt => opt.validate(slope));
        return MAX_TIME_OPTIONS.map((opt: any) => {
            opt.isValid = !isSubmitted || isValid;
            return opt;
        });
    }, [isSubmitted, slope]);

    const onRadioChangeHandler = useCallback(
        (value: string) => {
            setMaxType(value);
            dispatch(
                createTariffRuleActions.clearSlopeField({
                    keys: ['price', ...MAX_TIME_OPTIONS.map(opt => opt.key)],
                    index
                })
            );
        },
        [dispatch, index]
    );

    useEffect(() => {
        setMaxType(slope.price > 0 ? 'price' : 'time');
    }, [slope.price]);

    if (!rightRef || !leftRef || !headerRef) return <></>;

    return (
        <>
            {createPortal(
                <>
                    <Proportional />
                    &nbsp;
                    {tariffs}
                    {!isStringEmptyOrZero(slope.amount) ? ' / ' + slope.amount + '€' : ''}
                    {maxTariffs}
                </>,
                headerRef
            )}
            {createPortal(
                <PricingTime
                    values={slope}
                    onChange={onInputChange}
                    label="Ogni"
                    options={proportionalPricingTimeOptions}
                    integerOnly
                />,
                leftRef
            )}
            {createPortal(
                <>
                    <RadioButton onChange={onRadioChangeHandler} value={maxType} options={MAX_TYPE_OPTIONS} defaultValue="time" />
                    {maxType === 'time' && (
                        <PricingTime values={slope} onChange={onInputChange} options={maxTimeOptions} label="" integerOnly />
                    )}
                    {maxType === 'price' && (
                        <PricingTime
                            values={slope}
                            onChange={onInputChange}
                            options={[{ key: 'price', label: 'Euro', type: 'number', isValid: !isSubmitted || slope.price > 0 }]}
                            label=""
                        />
                    )}
                </>,
                rightRef
            )}
        </>
    );
};
export default ProportionalPricing;

