import { BillingStatus, OrderStatus, Tariff, VehicleType, VehicleTypes } from 'models';
import { Dispatch, FC, SetStateAction } from 'react';

export interface FormFields {
    label: string;
    id: string;
    required?: boolean;
    options?: { id: string; label: string }[];
    value?: any;
    prefix?: string;
    type?: string;
    placeholder?: string;
    validate?: any;
}

export type HTMLButtonType = 'button' | 'submit' | 'reset';

export type SvgIconProps = FC<{ fill?: string; size?: number; style?: React.CSSProperties | undefined }>;

export type TableButtonProps = {
    icon: JSX.Element;
    action: (item: any) => any;
    customClasses: string;
    id: string;
    disabled?: any;
};

export interface DashboardProps {
    label: string;
    value: string | number;
    info?: string;
}

export interface PaymentFilters {
    paymentMethods: string[];
    billing: string[];
    blacklist: string[];
}

export interface AreasFilters {
    city: string | null;
    date: AreaDateFilter;
    inProgress: string;
}

export interface AreaDateFilter {
    startDate: number;
    endDate: number;
}

export interface IMunicipalityAreasFilters {
    inProgress: string;
    status: string;
}

export enum CustomerServiceStatus {
    notRead = 'notRead',
    read = 'read',
    completed = 'completed'
}
export interface ParkingFilters {
    city: string | null;
    date: AreaDateFilter;
    vehicleTypes: string[];
    paymentState: string[];
    billingState: string[];
    paymentValueRange: number[];
    activeParking: string;
    inProgress: string;
}

export interface CustomerServiceFiltersItems {
    date: AreaDateFilter;
    blacklist: string;
    readStatus: string;
    requestStatus: string;
}
export interface CustomerServiceItem {
    User: {
        first_name: string;
        last_name: string;
        status: string;
        telephone_number?: string;
        email?: string;
    };
    id: string;
    title: string;
    description: string;
    userId?: string;
    createdAt: Date;
    updatedAt?: Date;
    status: string;
    ticketId?: string;
}
export interface BillsFilters {
    onlyUnbilled?: boolean;
    date: { startDate: number; endDate: number };
    totalUsers: { min: string; max: string };
    totalBillings: { min: string; max: string };
}

export type SetState<S> = Dispatch<SetStateAction<S>>;
export interface RadioButtonFilterFields {
    label: string;
    value: string;
}

interface Point {
    type: 'Point';
    coordinates: number[];
}

export interface Polygon {
    type: 'Polygon';
    coordinates: number[][][];
}

export type Geometry = Point | Polygon;
export interface TariffWithMunicipality extends Tariff {
    Municipality: { id: string; name: string };
}

export type TableExtractionFormat = 'CSV' | 'XLSX';

export interface ParkingDetail {
    amount: number;
    geometry: Geometry;
    ticketId: string;
    userId: string;
    firstName: string;
    lastName: string;
    name: string;
    code: string;
    orderAmount: number;
    paymentStatus: OrderStatus;
    city: string;
    plate: string;
    type: VehicleTypes;
    startTime: string;
    finishTime: string;
    billingStatus: BillingStatus;
    fees: number;
    updatedAt: string;
    orderId: string;
    orderType: string;
    timezone: string;
}

export interface NewTariff {
    name: string | undefined;
    slopeReprise: boolean | undefined;
    municipalityId?: string | undefined;
}

export type PaymentContract = {
    contractId: string;
    contractType: string;
    paymentCircuit: string;
    paymentInstrumentInfo: string;
    paymentMethod: string;
};

interface TicketInfo {
    freeTime: number;
    minTime: number;
    minPrice: number;
}

interface Slope {
    id: string;
    type: string;
    price: number;
    time: number;
    maxTime: number;
    maxPrice: number;
    multiplier: boolean;
    startHours: string;
    endHours: string;
    startTime: number;
    endTime: number;
    cardinality: number;
    tariffRuleId: string;
}

interface ValidityPeriods {
    id: string;
    type: string;
    startDay: string;
    endDay: string;
    weekNumber: number | null;
    month: number | null;
    year: number | null;
    day: number | null;
    holiday: boolean;
    beforeHoliday: boolean;
    textDay: string | null;
    tariffRuleId: string;
}

export interface Rule {
    id: string;
    name: string;
    cardinality: number;
    startTime: string;
    endTime: string;
    repeatedPeriodType: string;
    rateType: string;
    freeTime: number;
    days: any[];
    always: boolean;
    repeatYears: boolean;
    minPrice: number;
    minTime: number;
    tariffId: string;
    Slopes: Slope[];
    ValidityPeriods: ValidityPeriods[];
    VehicleTypes: VehicleType[];
    appliedStart: string;
    appliedEnd: string;
}

interface AppliedSlope {
    id: string;
    type: string;
    price: number;
    time: number;
    maxTime: number;
    maxPrice: number;
    multiplier: boolean;
    startHours: string;
    endHours: string;
    startTime: number;
    endTime: number;
    cardinality: number;
    tariffRuleId: string;
}

interface AppliedSlopeInfo {
    start: string;
    end: string;
    rule: Rule;
    appliedSlope: AppliedSlope;
    nCycle: number;
}

export interface TariffSimulationData {
    totalAmount: number;
    appliedSlopes: AppliedSlopeInfo[];
    municipalityId: string;
    ticketInfo: TicketInfo;
    tariffId: string;
    isBlocking: boolean;
}

