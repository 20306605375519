import { Typography } from '@mui/material';
import { ArrowForward } from 'components/icons';
import TrendIcon from 'components/trendIcon/TrendIcon';
import { PickedArea } from 'models';
import { FC, useCallback, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Popup } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { TableTypes, areasActions, tabActions } from 'store';
import { useGetAreaByIdQuery } from 'store/rtk/area.service';
import colors from 'styles/modules/colors.module.scss';
import turf from 'turf';
import classes from './MapPopup.module.scss';

type MapPopupAttributes = {
    pickedArea: PickedArea;
};

const MapPopup: FC<MapPopupAttributes> = ({ pickedArea }) => {
    const { data, isFetching } = useGetAreaByIdQuery({ id: pickedArea!.id }, { skip: !pickedArea });
    const polygon = useRef<string | null>(null);
    const popUpRef = useRef<any>(null);
    const screenWidth = window.innerWidth;

    const dispatch = useDispatch();
    const position = useMemo(() => {
        if (!data) return { longitude: 0, latitude: 0 };
        const point = turf.pointOnSurface(turf.feature(data.geometry as any));
        return { longitude: point.geometry.coordinates[0], latitude: point.geometry.coordinates[1] };
    }, [data]);

    const onClose = useCallback(() => {
        const oldValue = polygon.current;
        polygon.current = pickedArea?.id;
        if (pickedArea && oldValue && oldValue === pickedArea?.id) dispatch(areasActions.setPickedArea(undefined));
    }, [pickedArea]);

    if (!pickedArea || isFetching || !data) return <></>;

    const CustomMapPopup = () =>
        createPortal(
            <div
                className={classes.popup}
                style={{
                    visibility: popUpRef.current?._pos ? 'visible' : 'hidden',
                    position: 'absolute',
                    top: popUpRef.current?._pos?.y! + 4 || 0,
                    left: popUpRef.current?._pos?.x! + screenWidth / 2 + 80 || 0
                }}
            >
                <div>
                    <Typography>
                        {data.code} - {pickedArea.street}
                    </Typography>
                    <Typography>{data.cityName}</Typography>
                    <Typography className={classes.totalAmount}>
                        {data.totalAmount?.toFixed(2) || 0}€{' '}
                        <TrendIcon pastMonthAmount={data.pastMonthAmount} currentMonthAmount={data.currentMonthAmount} />
                    </Typography>
                </div>
                <button
                    onClick={() => {
                        dispatch(areasActions.setSelectedArea(data));
                        dispatch(tabActions.setActiveTable(TableTypes.areas));
                        dispatch(areasActions.setPickedArea(undefined));
                    }}
                >
                    <ArrowForward fill={colors.primary0} />
                </button>
            </div>,
            document.getElementById('custom_map_popup')!
        );

    return (
        <Popup
            className={classes.refPopup}
            ref={popUpRef}
            key={`${pickedArea.latitude}-${pickedArea.longitude}`}
            closeButton={false}
            anchor="left"
            maxWidth="0px"
            longitude={position.longitude}
            latitude={position.latitude}
            closeOnClick={false}
            onClose={onClose}
        >
            <CustomMapPopup />
        </Popup>
    );
};

export default MapPopup;

