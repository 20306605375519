import { SetState } from 'helpers';
import { FC } from 'react';
import BillingFilters from './billingFilters/BillingFilters';
import BlacklistFilters from './blacklistFilters/BlacklistFilters';
import { UserFilters } from 'models';
import classes from './PaymentFilters.module.scss';

type PaymentFiltersProps = FC<{
    activeFilters: UserFilters;
    setActiveFilters: SetState<UserFilters>;
}>;

const PaymentFilters: PaymentFiltersProps = ({ activeFilters, setActiveFilters }) => {
    return (
        <div className={classes.paymentContainer}>
            <p className={classes.title}>Pagamenti</p>
            <div className={classes.filterContainer}>
                <BillingFilters activeFilters={activeFilters.billing} setActiveFilters={setActiveFilters} />
                <BlacklistFilters activeFilters={activeFilters.blacklist} setActiveFilters={setActiveFilters} />
            </div>
        </div>
    );
};

export default PaymentFilters;

