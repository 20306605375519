import BlacklistSwitchButton from 'components/buttons/blacklistSwitchButton/BlacklistSwitchButton';
import classes from './ModalEnableMunicipality.module.scss';
import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import { MunicipalityCounter } from 'models';
import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, municipalityActions } from 'store';
import { useLazyUpdateMunicipalityStatusQuery } from 'store/rtk/municipality.service';

type ModalEnableMunicipalityProps = FC<{}>;

const ModalEnableMunicipality: ModalEnableMunicipalityProps = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { selectedMunicipality } = useSelector((state: RootState) => state.municipalities);
    const [updateMunicipalityStatus] = useLazyUpdateMunicipalityStatusQuery();
    const dispatch = useDispatch();

    const onClickHandler = useCallback(async () => {
        if (!selectedMunicipality) return;
        const newStatus = !selectedMunicipality.isDisabled;

        const res = await updateMunicipalityStatus({
            id: selectedMunicipality.id,
            isDisabled: newStatus
        });
        if (!res.data) return;
        const updatedMunicipality: MunicipalityCounter = { ...selectedMunicipality, isDisabled: res.data.isDisabled };
        dispatch(municipalityActions.setSelectedMunicipality(updatedMunicipality));
        setIsOpen(false);
    }, [selectedMunicipality, updateMunicipalityStatus, dispatch]);

    const onChangeDisableHandler = () => {
        if (selectedMunicipality?.isDisabled) onClickHandler();
        else setIsOpen(true);
    };

    const handleClose = useCallback(() => setIsOpen(false), []);

    return (
        <>
            <BlacklistSwitchButton checked={selectedMunicipality?.isDisabled} onChange={onChangeDisableHandler} label="" />
            <Modal className={classes.modalArea} title="Disabilita citta'" isOpen={isOpen} handleClose={handleClose}>
                Proseguendo, la citta' rimarrà salvata ma verrà resa non disponibile nell’applicazione e di conseguenza verranno
                disabilitate anche le aree al suo interno.
                <div className={classes.buttons}>
                    <TextButton onClick={handleClose} className="secondary">
                        Annulla
                    </TextButton>
                    <TextButton onClick={onClickHandler} className="primary">
                        Disabilita citta'
                    </TextButton>
                </div>
            </Modal>
        </>
    );
};
export default ModalEnableMunicipality;

