import { IconButton, SwitchButton } from 'components/buttons';
import { BillingsBackground, Filters, IsOnGoing } from 'components/icons';
import Searchbar from 'components/searchbar/Searchbar';
import { Billing } from 'models';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, billingsActions } from 'store';
import { useGetBillingsQuery } from 'store/rtk/billing.service';
import classes from './Billings.module.scss';
import BillingDetails from './billingDetails/BillingDetails';
import BillingFiltersTab from './billingFiltersTab/BillingFiltersTab';
import BillingList from './billingList/BillingList';
import { DEFAULT_BILLS_FILTER_VALUES } from 'helpers';
import deepEqual from 'deep-equal';

const Billings: FC = () => {
    const [isFilterTabOpen, setIsFilterTabOpen] = useState(false);
    const { billings, filteredBillings, selectedBilling, activeFilters, pages } = useSelector((x: RootState) => x.billings);
    const [searchedWord, setSearchedWord] = useState<string>('');
    const dispatch = useDispatch();
    const { data: billingsData, refetch: refetchBillingsData } = useGetBillingsQuery({
        offset: pages * 20,
        searchedWord,
        date: JSON.stringify(activeFilters.date) as any,
        totalUsers: JSON.stringify(activeFilters.totalUsers) as any,
        totalBillings: JSON.stringify(activeFilters.totalBillings) as any
    });

    useEffect(() => {
        const ref = setInterval(() => {
            dispatch(billingsActions.setPages(0));
            setTimeout(() => refetchBillingsData(), 100);
        }, 30000);
        return () => clearInterval(ref);
    }, []);

    const searchBarHandler = (searchedWord: string) => {
        dispatch(billingsActions.setBillings([]));
        setSearchedWord(searchedWord);
        setTimeout(() => {
            dispatch(billingsActions.setPages(0));
        }, 100);
    };

    const handleBillingClick = (billing: Billing) => {
        dispatch(billingsActions.setSelectedBilling(billing));
        setIsFilterTabOpen(false);
    };

    const billingList = useMemo(() => {
        return activeFilters.onlyUnbilled ? filteredBillings : billings;
    }, [activeFilters.onlyUnbilled, filteredBillings, billings]);

    const areFiltersActive = useMemo(() => !deepEqual(activeFilters, DEFAULT_BILLS_FILTER_VALUES), [activeFilters]);

    const filtersAndBillingDetails = useMemo(() => {
        if (selectedBilling && !isFilterTabOpen) return <BillingDetails refetchBillingsData={refetchBillingsData} />;
        if (isFilterTabOpen) return <BillingFiltersTab closeFilter={setIsFilterTabOpen} />;
        return (
            <div className={classes.background}>
                <BillingsBackground />
                Seleziona un’azienda.
            </div>
        );
    }, [selectedBilling, isFilterTabOpen, refetchBillingsData]);

    return (
        <div className={classes.container}>
            <div className={classes.left}>
                <div className={classes.topTools}>
                    <div className={classes.bar}>
                        <p>Fatture</p>
                        <SwitchButton
                            onChange={() => {
                                dispatch(
                                    billingsActions.setActiveFilters({
                                        ...activeFilters,
                                        onlyUnbilled: !activeFilters.onlyUnbilled
                                    })
                                );
                            }}
                            checked={activeFilters.onlyUnbilled}
                            label="Solo da evadere"
                        />
                    </div>
                    <div className={classes.bar}>
                        <Searchbar value={searchedWord} onChange={searchBarHandler} />
                        <div className={classes.filterButton}>
                            <IconButton
                                className="tertiary"
                                icon={<Filters />}
                                onClick={() => setIsFilterTabOpen(!isFilterTabOpen)}
                            />
                            {areFiltersActive && (
                                <span className={classes.active}>
                                    <IsOnGoing />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes.cardList}>
                    <BillingList billingsData={billingsData} billingList={billingList} handleBillingClick={handleBillingClick} />
                </div>
            </div>
            <div className={classes.right}>{filtersAndBillingDetails}</div>
        </div>
    );
};

export default Billings;

