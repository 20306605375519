import { FC } from 'react';

export const Prohibition: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.001 0C4.47813 0 0.000976562 4.47715 0.000976562 10C0.000976562 15.5228 4.47813 20 10.001 20C15.5238 20 20.001 15.5228 20.001 10C20.001 4.47715 15.5238 0 10.001 0ZM1.50098 10C1.50098 7.92282 2.24606 6.01967 3.48354 4.54322L15.4578 16.5174C13.9813 17.7549 12.0782 18.5 10.001 18.5C5.30656 18.5 1.50098 14.6944 1.50098 10ZM16.5184 15.4568L4.5442 3.48256C6.02065 2.24508 7.9238 1.5 10.001 1.5C14.6954 1.5 18.501 5.30558 18.501 10C18.501 12.0772 17.7559 13.9803 16.5184 15.4568Z"
                fill="#FB6A6F"
            />
        </svg>
    );
};

