import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { history } from 'helpers';
import { env } from 'environments';
import { UserToken } from 'models/user.model';

export interface AuthState {
    user?: UserToken;
    token?: string;
    tokenExpiration?: string;
    error: any;
}

export interface UpdateUserBody {
    firstName: string | undefined;
    lastName: string | undefined;
}

const name = 'auth';
const initialState: AuthState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.verifyOtp.fulfilled, (state, action) => login(state, action));
        builder.addDefaultCase((state, action) => {
            state.error = action.error;
        });
    }
});

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

function createInitialState() {
    const user = localStorage.getItem('user');
    const permissions = localStorage.getItem('permissions');
    const groupName = localStorage.getItem('groupName');

    return {
        user: user && JSON.parse(user),
        token: localStorage.getItem('token') as string,
        permissions: permissions && (JSON.parse(permissions) as string[]),
        tokenExpiration: localStorage.getItem('tokenExpiration'),
        groupName,
        error: undefined
    } as AuthState;
}

function createReducers() {
    return { logout };

    function logout(state: AuthState) {
        state.user = undefined;
        state.token = undefined;
        state.tokenExpiration = undefined;
        localStorage.clear();
        history.navigate('/');
    }
}

interface LoginParams {
    otpToVerify: string;
    id: string;
}

function createExtraActions() {
    return {
        verifyOtp: loginThunk()
    };

    function loginThunk() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ otpToVerify, id }: LoginParams) =>
                await axios.post(`${env.reactAppSocketUrl}${env.apiUrl}/auth/otp`, { otpToVerify, id }).catch(err => {
                    return err.response.data;
                })
        );
    }
}

function login(state: AuthState, action: PayloadAction<any>) {
    if (action.payload.message) return;
    const user = action.payload.data as UserToken;
    state.token = user.token;
    state.tokenExpiration = user.tokenExpiration;
    state.user = user;
    localStorage.setItem('tokenExpiration', user.tokenExpiration);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', user.token);
    history.navigate('/backoffice');
}

