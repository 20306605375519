import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { RadioChecked } from 'components/icons';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import colors from 'styles/modules/colors.module.scss';
import classes from './RadioButton.module.scss';

type RadioButtonProps = FC<{
    onChange: (...data: any) => void;
    value?: string;
    options: { value: string; label: string }[];
    className?: string;
    defaultValue?: string;
}>;

const RadioButton: RadioButtonProps = ({ onChange, options, value, className, defaultValue }) => {
    const buttons = useMemo(
        () =>
            options.map(field => (
                <FormControlLabel
                    key={field.value}
                    value={field.value}
                    control={<Radio icon={<RadioChecked fill={colors.primary10} />} checkedIcon={<RadioChecked />} />}
                    label={field.label}
                />
            )),
        [options]
    );

    const onChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value), [onChange]);

    return (
        <FormControl>
            <RadioGroup
                onChange={onChangeHandler}
                value={value}
                defaultValue={defaultValue}
                className={`${classes.radio} ${className}`}
            >
                {buttons}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButton;

