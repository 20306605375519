import { Autocomplete, TextField } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import classes from './CityFilter.module.scss';
import { ClearField } from 'components/icons';
import { useSelector } from 'react-redux';
import { AuthState, RootState, TabState, TableTypes } from 'store';
import { FiltersState } from 'store/filters.slice';
import { useLazyGetAllMunicipalitiesNameQuery } from 'store/rtk/municipality.service';
import axios from 'axios';

type CityFilterProps = FC<{
    activeFilter: string | null;
    setActiveFilters: any;
    className?: string;
}>;

const CityFilter: CityFilterProps = ({ activeFilter, setActiveFilters, className }) => {
    const { city } = useSelector<RootState>(x => x.filters) as FiltersState;
    const { activeTable } = useSelector<RootState>(x => x.tab) as TabState;
    const { token } = useSelector<RootState>(x => x.auth) as AuthState;
    const [getMunicipalitiesName] = useLazyGetAllMunicipalitiesNameQuery();
    const [municipalities, setMunicipalities] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            const { data } = await getMunicipalitiesName();
            if (!data) {
                setMunicipalities([]);
                return;
            }
            setMunicipalities(data.map(data => data.name));
        })();
    }, [getMunicipalitiesName]);

    const value = useMemo(
        () => ([TableTypes.areas, TableTypes.stops].includes(activeTable) ? city : activeFilter),
        [activeFilter, activeTable, city]
    );

    const onChangeHandler = async (value: any) => {
        if (value === null)
            await axios.put(`api/v1/users/reset-municipality-default-filter`, undefined, {
                headers: { Authorization: token }
            });
        setActiveFilters('city', value == null ? '' : value);
    };

    return (
        <div className={`${classes.cityFilterContainer} ${className}`}>
            <p>Città</p>
            <label className="small">
                <b>Soste in questa città:</b>
            </label>
            <Autocomplete
                noOptionsText={'La ricerca non ha prodotto risultati.'}
                popupIcon={null}
                clearIcon={<ClearField />}
                disablePortal
                sx={{ width: '20rem', height: '2.25rem', color: 'white' }}
                options={municipalities}
                value={value}
                onChange={(_event, newValue) => onChangeHandler(newValue)}
                renderInput={params => <TextField {...params} />}
            />
        </div>
    );
};

export default CityFilter;

