import Map from './map/Map';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, municipalityActions } from 'store';
import Tables from './tables/Tables';
import 'mapbox-gl/dist/mapbox-gl.css';
import Sidebar from './sidebar/Sidebar';
import Settings from './settings/Settings';
import Billings from './billings/Billings';
import CustomerService from './customerService/CustomerService';
import MunicipalitySettings from './municipalitySettings/MunicipalitySettings';
import { MapProvider } from 'context';

const Backoffice: FC = () => {
    const { activeTab } = useSelector((x: RootState) => x.tab);
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeTab !== TabTypes.municipalitySettings) dispatch(municipalityActions.resetCreateEditArea());
    }, [activeTab, dispatch]);

    const map = useMemo(() => <Map />, []);

    return (
        <div style={{ display: 'flex' }}>
            <MapProvider>
                <Sidebar />
                {activeTab === TabTypes.home && (
                    <>
                        <Tables />
                        {map}
                    </>
                )}
                {activeTab === TabTypes.settings && <Settings />}
                {activeTab === TabTypes.billing && <Billings />}
                {activeTab === TabTypes.customerService && <CustomerService />}
                {activeTab === TabTypes.municipalitySettings && <MunicipalitySettings map={map} />}
            </MapProvider>
        </div>
    );
};

export default Backoffice;

