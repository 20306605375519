import { IconButton } from 'components/buttons';
import TextButton from 'components/buttons/textButton/TextButton';
import { ArrowBack } from 'components/icons';
import GenericSelect from 'components/select/GenericSelect';
import Snackbar from 'components/snackbar/Snackbar';
import { DEFAULT_LAYER_DATA } from 'helpers';
import { Tariff } from 'models';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, areasActions, drawActions, municipalityActions, snackbarActions } from 'store';
import {
    areaApi,
    useLazyCreateAreaQuery,
    useLazyEditAreaQuery,
    useLazyGetAreaByIdQuery,
    useResetScheduleMutation
} from 'store/rtk/area.service';
import { useGetAllMunicipalitiesQuery } from 'store/rtk/municipality.service';
import { useLazyGetTariffsByMunicipalityQuery } from 'store/rtk/tariff.service';
import ModalEnableArea from 'views/backoffice/municipalitySettings/modalEnableArea/ModalEnableArea';
import classes from './CreateOrEditArea.module.scss';
import ScheduleChip from './scheduleChip/ScheduleChip';
import ResetConfirmationModal from './resetConfirmationModal/ResetConfirmationModal';

const CreateOrEditArea: FC = () => {
    const [resetConfirmationModalIsOpen, setResetConfirmationModalIsOpen] = useState(false);
    const [getAreaById] = useLazyGetAreaByIdQuery();
    const { selectedArea: selectedAreaInTable } = useSelector((x: RootState) => x.areas);
    const { selectedMunicipality, isEditingArea, selectedArea } = useSelector((x: RootState) => x.municipalities);
    const [tariffs, setTariffs] = useState<Tariff[]>([]);
    const [isDisabledFor, setIsDisabledFor] = useState('');
    const { data } = useSelector((x: RootState) => x.draw);
    const dispatch = useDispatch();
    const [createArea, { isFetching }] = useLazyCreateAreaQuery();
    const [resetSchedule] = useResetScheduleMutation();
    const [selectTariffValue, setSelectTariffValue] = useState('');
    const [editArea, { isFetching: isFetchingArea }] = useLazyEditAreaQuery();
    const {
        register,
        handleSubmit,
        reset,
        formState: { isValid, isDirty }
    } = useForm();
    const [getTariffs] = useLazyGetTariffsByMunicipalityQuery();
    const { data: municipalities } = useGetAllMunicipalitiesQuery();

    useEffect(() => {
        !municipalities || dispatch(municipalityActions.setMunicipalities(municipalities));
    }, [municipalities, dispatch]);

    useEffect(() => {
        (async () => {
            if (!selectedMunicipality?.id) return;
            const res = await getTariffs({ municipalityId: selectedMunicipality.id });
            if (!res.data) return;
            setTariffs(res.data);
        })();
    }, [getTariffs, selectedMunicipality]);

    useEffect(() => {
        if (selectedArea?.cityName && municipalities) {
            const selectedMunicipality = municipalities.find(municipality => municipality.name === selectedArea.cityName);
            dispatch(municipalityActions.setSelectedMunicipality(selectedMunicipality));
        }
    }, [municipalities, dispatch, selectedArea?.cityName]);

    useEffect(() => {
        setIsDisabledFor(selectedArea?.isDisabledFor ?? '');
    }, [selectedArea]);

    const isDisabled = useMemo(() => {
        return (isDirty && !isValid) || !data.features.length;
    }, [data.features.length, isDirty, isValid]);

    const onClickHandler = () => {
        dispatch(drawActions.setMode('view'));
        if (isEditingArea) {
            dispatch(municipalityActions.toggleEditArea());
        } else {
            dispatch(municipalityActions.toggleCreateArea());
        }
        dispatch(drawActions.setData(DEFAULT_LAYER_DATA));
    };

    const onSubmit = async (formValues: any) => {
        if (isFetching || isFetchingArea || !selectedMunicipality) return;
        if (!isEditingArea) {
            await createAreaHandler(formValues);
            dispatch(areaApi.util.resetApiState());
            return;
        }
        await editAreaHandler(formValues);
    };

    const createAreaHandler = async (formValues: any) => {
        try {
            const { data: res, error } = await createArea({
                areaCreation: {
                    geometry: data.features[0].geometry,
                    municipalityId: selectedMunicipality!.id,
                    ...formValues,
                    isDisabled: isDisabledFor.length > 0,
                    isDisabledFor
                }
            });
            if (error) {
                if ((error as any).data?.message === 'area-code-already-used')
                    dispatch(
                        snackbarActions.setMessageAndType({
                            message: `Codice Area duplicato.`,
                            type: 'error'
                        })
                    );
                else
                    dispatch(
                        snackbarActions.setMessageAndType({
                            message: `Si è verificato un errore durante la creazione dell'area.`,
                            type: 'error'
                        })
                    );
                dispatch(snackbarActions.setIsOpen(true));
                return;
            }
            if (!res) return;
            dispatch(municipalityActions.addArea(res));
            reset();
            dispatch(snackbarActions.setMessageAndType({ message: `Area creata con successo.` }));
            dispatch(snackbarActions.setIsOpen(true));
            dispatch(municipalityActions.toggleCreateArea());
        } catch (error) {
            console.error(error);
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Si è verificato un errore durante la creazione dell'area.`,
                    type: 'error'
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
        }
    };

    const editAreaHandler = async (formValues: any) => {
        try {
            const { data: res, error } = await editArea({
                ...formValues,
                id: selectedArea?.id,
                geometry: data.features[0].geometry
            });
            if (error) {
                if ((error as any).data?.message === 'area-code-already-used')
                    dispatch(
                        snackbarActions.setMessageAndType({
                            message: `Codice Area duplicato.`,
                            type: 'error'
                        })
                    );
                else
                    dispatch(
                        snackbarActions.setMessageAndType({
                            message: `Si è verificato un errore durante la modifica dell'area.`,
                            type: 'error'
                        })
                    );
                dispatch(snackbarActions.setIsOpen(true));
                return;
            }
            if (!res) return;
            const tariffName = selectOptions.find(v => v.id === res.tariffId)?.label as any;
            if (selectedAreaInTable) {
                const newArea = await getAreaById({ id: selectedAreaInTable.id }).unwrap();
                dispatch(areasActions.setSelectedArea(newArea));
            }
            dispatch(areaApi.util.resetApiState());
            dispatch(
                municipalityActions.replaceUpdatedArea({
                    ...selectedArea,
                    ...res,
                    tariffName
                })
            );
            dispatch(snackbarActions.setMessageAndType({ message: `Area modificata con successo.` }));
            dispatch(snackbarActions.setIsOpen(true));
            dispatch(municipalityActions.toggleEditArea());
        } catch (error) {
            console.error(error);
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Si è verificato un errore durante la modifica dell'area.`,
                    type: 'error'
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
        }
    };

    const handleScheduleReset = async (id: string) => {
        try {
            const res = await resetSchedule({ areaId: id });
            if ('data' in res) {
                dispatch(municipalityActions.setSelectedArea(res.data));
            } else {
                dispatch(
                    snackbarActions.setMessageAndType({
                        message: `Si è verificato un errore durante la cancellazione della scheda.`,
                        type: 'error'
                    })
                );
            }
        } catch (error) {
            console.error(error);
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Si è verificato un errore durante la cancellazione della scheda.`,
                    type: 'error'
                })
            );
        }
    };

    const selectOptions = useMemo(() => {
        return tariffs.map(tariff => ({
            id: tariff.id,
            label: tariff.name
        }));
    }, [tariffs]);

    if (!selectedMunicipality) return <></>;

    return (
        <>
            <div className={classes.header}>
                <div className={classes.back}>
                    <IconButton icon={<ArrowBack />} onClick={onClickHandler} className="tertiary" />
                    <p>
                        <span>{selectedMunicipality?.name} / </span> Area - {isEditingArea ? 'Modifica Area' : 'Nuova Area'}
                    </p>
                </div>
                <TextButton className="tertiary" onClick={onClickHandler}>
                    Annulla
                </TextButton>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <div className={classes.info}>
                    <p>Informazioni</p>
                    <div className={classes.schedule}>
                        {selectedArea?.disabledFrom && (
                            <ScheduleChip
                                label={selectedArea?.disabledFrom}
                                close={() => setResetConfirmationModalIsOpen(true)}
                            />
                        )}
                        <ModalEnableArea setIsDisabledFor={setIsDisabledFor} isDisabledFor={isDisabledFor} />
                        <ResetConfirmationModal
                            isOpen={resetConfirmationModalIsOpen}
                            setIsOpen={setResetConfirmationModalIsOpen}
                            onClickHandler={() => selectedArea && handleScheduleReset(selectedArea.id)}
                            selectedArea={selectedArea}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.field}>
                        <label htmlFor="code">
                            <b className="small">Codice</b>
                        </label>
                        <input
                            {...register('code', { required: true, value: isEditingArea ? selectedArea?.code : '' })}
                            type="number"
                            placeholder="es. 123"
                            defaultValue={isEditingArea ? selectedArea?.code : ''}
                        />
                    </div>
                    <div className={classes.field}>
                        <label htmlFor="name">
                            <b className="small">Nome</b>
                        </label>
                        <input
                            {...register('name', { required: true, value: isEditingArea ? selectedArea?.name : '' })}
                            placeholder="es. Mercato di via delle Viole"
                            defaultValue={isEditingArea ? selectedArea?.name : ''}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.field}>
                        <label htmlFor="city">
                            <b className="small">Città</b>
                        </label>
                        <input
                            disabled={true}
                            className={classes.disabled}
                            {...register('city', { required: true, value: selectedMunicipality?.name })}
                            placeholder="es. Alessandria"
                            defaultValue={selectedMunicipality?.name}
                        />
                    </div>
                    <div className={classes.field}>
                        <label htmlFor="parkingSpaces">
                            <b className="small">Stalli</b>
                        </label>
                        <input
                            {...register('parkingSpaces', {
                                required: true,
                                valueAsNumber: true,
                                value: isEditingArea ? selectedArea?.parkingSpaces : ''
                            })}
                            type="number"
                            placeholder="es. 456"
                            defaultValue={isEditingArea ? selectedArea?.parkingSpaces : ''}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.field}>
                        <label htmlFor="street">
                            <b className="small">Indirizzo</b>
                        </label>
                        <input
                            className={classes.alignInput}
                            {...register('street', { required: false, value: !isEditingArea ? '' : selectedArea?.street })}
                            placeholder="es. via delle Viole"
                            defaultValue={!isEditingArea ? '' : selectedArea?.street}
                        />
                    </div>
                </div>
                <p>Tariffa</p>
                <div className={classes.tariff}>
                    <label htmlFor="city">
                        <b className="small">Tariffa</b>
                    </label>
                    <div className={classes.select}>
                        <GenericSelect
                            register={register('tariffId', {
                                required: false,
                                value: isEditingArea ? selectedArea?.tariffId : selectTariffValue
                            })}
                            placeholder="es. Alessandria"
                            onChange={(selected: any) => setSelectTariffValue(selected.target.value)}
                            defaultValue={isEditingArea ? selectedArea?.tariffId : ''}
                            sx={{
                                '.MuiSelect-select': {
                                    display: `${selectOptions.length ? 'block' : 'none'}`
                                }
                            }}
                            options={selectOptions}
                            disabled={!selectOptions.length}
                        />
                    </div>
                </div>
                <TextButton className={`primary ${classes.createBtn}`} disabled={isDisabled}>
                    Salva area
                </TextButton>
            </form>
            <Snackbar />
        </>
    );
};

export default CreateOrEditArea;

