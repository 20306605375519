import BlacklistSwitchButton from 'components/buttons/blacklistSwitchButton/BlacklistSwitchButton';
import TextButton from 'components/buttons/textButton/TextButton';
import { ArrowDown } from 'components/icons';
import Modal from 'components/modal/Modal';
import RadioButton from 'components/radioButton/RadioButton';
import { SetState } from 'helpers';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, municipalityActions } from 'store';
import { useLazyScheduleAreaStatusQuery, useLazyUpdateAreaStatusQuery } from 'store/rtk/area.service';
import { useLazyGetPendingTicketsQuery } from 'store/rtk/parking.service';
import ConfirmDisableModal from './confirmDisableModal/ConfirmDisableModal';
import classes from './ModalEnableArea.module.scss';

const SCHEDULE_OPTIONS = [
    { value: 'now', label: 'Disablita ora' },
    { value: 'later', label: 'Programma disabilitazione' }
];

type ModalEnableAreaProps = FC<{
    isDisabledFor: string;
    setIsDisabledFor: SetState<string>;
}>;

const ModalEnableArea: ModalEnableAreaProps = ({ setIsDisabledFor, isDisabledFor }) => {
    const [text, setText] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isScheduling, setIsScheduling] = useState('now');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [startTime, setStartTime] = useState<string>('');
    const [endTime, setEndTime] = useState<string>('');
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const { selectedArea, selectedMunicipality } = useSelector((state: RootState) => state.municipalities);
    const [updateAreaStatus] = useLazyUpdateAreaStatusQuery();
    const [checkPendingTickets] = useLazyGetPendingTicketsQuery();
    const [scheduleUpdateAreaStatus] = useLazyScheduleAreaStatusQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedArea) return;
        setIsDisabledFor(selectedArea.isDisabledFor);
        setText(selectedArea.isDisabledFor);
        if (!selectedArea.disabledFrom || !selectedArea.disabledTo) return;
        const startDate = moment(selectedArea.disabledFrom).tz(selectedMunicipality?.timezone || 'Europe/Rome');
        const endDate = moment(selectedArea.disabledTo).tz(selectedMunicipality?.timezone || 'Europe/Rome');
        setStartTime(startDate.format('HH:mm'));
        setEndTime(endDate.format('HH:mm'));
        setEndDate(endDate.toDate());
        setStartDate(startDate.toDate());
        setIsScheduling('later');
    }, [selectedArea, selectedMunicipality, setIsDisabledFor]);

    const startDateTime = useMemo(() => {
        if (!startDate || !startTime) return null;
        return moment(startDate.toDateString() + ' ' + startTime).tz(selectedMunicipality?.timezone || 'Europe/Rome', true);
    }, [startDate, startTime, selectedMunicipality?.timezone]);

    const endDateTime = useMemo(() => {
        if (!endDate || !endTime) return null;
        return moment(endDate.toDateString() + ' ' + endTime).tz(selectedMunicipality?.timezone || 'Europe/Rome', true);
    }, [endDate, endTime, selectedMunicipality?.timezone]);

    const isValid = useMemo(
        () => startDateTime && endDateTime && endDateTime.isAfter(startDateTime),
        [startDateTime, endDateTime]
    );

    const isChecked = useMemo(() => {
        if (!selectedArea) {
            return isDisabledFor.length > 0;
        } else return selectedArea.isDisabled;
    }, [selectedArea, isDisabledFor]);

    const onCloseHandler = useCallback(() => {
        setText('');
        setStartDate(null);
        setStartTime('');
        setIsScheduling('now');
        setConfirmModalOpen(false);
        setEndDate(null);
        setEndTime('');
        setIsOpen(false);
    }, []);

    const scheduleAreaStatus = useCallback(
        async (status: boolean) => {
            if (!selectedArea) return;
            const update = await scheduleUpdateAreaStatus({
                id: selectedArea.id,
                isDisabled: status,
                isDisabledFor: text,
                startDate: startDateTime?.toISOString() || '',
                endDate: endDateTime?.toISOString() || ''
            });
            setIsError(update.isError);
            if (update.isError) return setConfirmModalOpen(false);
            onCloseHandler();
        },
        [selectedArea, scheduleUpdateAreaStatus, text, startDateTime, endDateTime, onCloseHandler]
    );

    const onClickHandler = useCallback(async () => {
        if (!selectedArea) {
            setIsDisabledFor(text);
            setIsOpen(false);
            return;
        }
        const newStatus = !selectedArea.isDisabled;
        if (isScheduling === 'later') {
            const res = await checkPendingTickets({ areaId: selectedArea.id, areaDisabledFrom: startDateTime?.toISOString() });
            res.data?.length && res.data?.length > 0 ? setConfirmModalOpen(true) : await scheduleAreaStatus(newStatus);
            startDateTime &&
                endDateTime &&
                dispatch(
                    municipalityActions.setSelectedArea({
                        ...selectedArea,
                        disabledFrom: startDateTime.toISOString(),
                        disabledTo: endDateTime.toISOString(),
                        isDisabledFor: text
                    })
                );
            return;
        }
        const res = await updateAreaStatus({
            id: selectedArea.id,
            isDisabled: newStatus,
            isDisabledFor: text
        });
        if (!res.data) return;
        dispatch(municipalityActions.setSelectedArea(res.data));
        setIsOpen(false);
    }, [
        selectedArea,
        isScheduling,
        updateAreaStatus,
        text,
        dispatch,
        setIsDisabledFor,
        checkPendingTickets,
        startDateTime,
        scheduleAreaStatus,
        endDateTime
    ]);

    const handleMotivationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };

    const onChangeDisableHandler = () => {
        if (!selectedArea) {
            if (isDisabledFor.length > 0) {
                setIsDisabledFor('');
            } else {
                setIsOpen(true);
            }
            return;
        } else {
            if (selectedArea.isDisabled) onClickHandler();
            else setIsOpen(true);
        }
    };

    return (
        <>
            <BlacklistSwitchButton checked={isChecked} onChange={onChangeDisableHandler} label="Area abilitata" />
            <Modal className={classes.modalArea} title="Disabilita area" isOpen={isOpen} handleClose={onCloseHandler}>
                Proseguendo, l’area rimarrà salvata ma verrà resa non disponibile nell’applicazione. Riporta sotto la motivazione
                brevemente, il messaggio verrà allegato all’area e sarà visibile agli utenti.
                <div className={classes.motivation}>
                    <label className="small lightBlue">Motivazione</label>
                    <textarea
                        onChange={handleMotivationChange}
                        maxLength={80}
                        placeholder="Es. Lavori di manutenzione"
                        defaultValue={selectedArea?.isDisabledFor}
                        value={text}
                    />
                </div>
                {selectedArea && (
                    <RadioButton
                        onChange={setIsScheduling}
                        value={isScheduling}
                        options={SCHEDULE_OPTIONS}
                        defaultValue={isScheduling}
                    />
                )}
                {isScheduling === 'later' && (
                    <div className={classes.schedule}>
                        <label>
                            <b>dal</b>
                        </label>
                        <div className={classes.datePickerContainer}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className={`${!isValid ? classes.dateError : ''}`}
                                placeholderText={'Seleziona'}
                                wrapperClassName="datePicker"
                                selected={startDate}
                                onChange={setStartDate}
                            />
                            <ArrowDown />
                        </div>
                        <div className={classes.field}>
                            <input
                                onChange={(event: any) => setStartTime(event?.target.value)}
                                value={startTime}
                                type="time"
                                placeholder=" "
                                className={!isValid ? classes.error : ''}
                            />
                        </div>
                        <label>
                            <b>al</b>
                        </label>
                        <div className={classes.datePickerContainer}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className={`${!isValid ? classes.dateError : ''}`}
                                placeholderText={'Seleziona'}
                                wrapperClassName="datePicker"
                                selected={endDate}
                                onChange={setEndDate}
                            />
                            <ArrowDown />
                        </div>
                        <div className={classes.field}>
                            <input
                                onChange={(event: any) => setEndTime(event?.target.value)}
                                value={endTime}
                                type="time"
                                placeholder=" "
                                className={!isValid ? classes.error : ''}
                            />
                        </div>
                    </div>
                )}
                {isError && <div className={classes.dateError}>* Data e/o orario non validi</div>}
                <div className={classes.buttons}>
                    <TextButton onClick={onCloseHandler} className="secondary">
                        Annulla
                    </TextButton>
                    <TextButton
                        onClick={onClickHandler}
                        className="primary"
                        disabled={text?.length === 0 || (isScheduling === 'later' && !isValid)}
                    >
                        Disabilita area
                    </TextButton>
                </div>
            </Modal>
            <ConfirmDisableModal
                isOpen={confirmModalOpen}
                setIsOpen={setConfirmModalOpen}
                onSubmit={() => scheduleAreaStatus(!selectedArea?.isDisabled)}
            />
        </>
    );
};
export default ModalEnableArea;

