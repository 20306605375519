import { env } from 'environments';
import { CustomerServiceFiltersItems, CustomerServiceItem, CustomerServiceStatus } from 'helpers';
import { BillingDetailsData, UserBilling, UsersBillings } from 'models';
import { Moment } from 'moment';

export const formatFeature = (geometry: any) => ({ geometry, type: 'Feature' });
export const isStringEmptyOrZero = (str: string | number) => str === undefined || str === '' || str === '0' || str === 0;

export function filterNonIssuedBillings(data: BillingDetailsData | undefined): BillingDetailsData | undefined {
    return (
        data && {
            ...data,
            usersBillings: data.usersBillings.flatMap(userBilling => ({
                ...userBilling,
                userBillings: userBilling.userBillings.filter(billing => billing.billing_status !== 'issued')
            }))
        }
    );
}

export function calculateChipsValues(data: BillingDetailsData) {
    let totalAmount = 0;
    let totalBillings = 0;
    let numberOfUsers = 0;
    if (!data) return;
    data.usersBillings.forEach((userBilling: UsersBillings) => {
        numberOfUsers += 1;
        userBilling.userBillings.forEach((billing: UserBilling) => {
            totalAmount += billing.amount;
            totalBillings += 1;
        });
    });

    return {
        totalAmount: totalAmount.toFixed(2),
        totalBillings,
        numberOfUsers
    };
}

export function applyFilters(data: CustomerServiceItem[], filters: CustomerServiceFiltersItems, searchedWord: string) {
    let filteredCustomerServices = [...data];
    filteredCustomerServices = filteredCustomerServices.filter(
        item =>
            item.User.first_name.toLocaleLowerCase().includes(searchedWord) ||
            item.User.last_name.toLocaleLowerCase().includes(searchedWord) ||
            item.description.toLocaleLowerCase().includes(searchedWord)
    );
    if (filters.date.startDate !== 0 && filters.date.endDate !== 0) {
        filteredCustomerServices = filteredCustomerServices.filter(item => {
            const createdAtInMs = new Date(item.createdAt).getTime();
            return createdAtInMs >= filters.date.startDate && createdAtInMs <= filters.date.endDate;
        });
    }
    if (filters.requestStatus !== 'all') {
        if (filters.requestStatus === CustomerServiceStatus.completed)
            filteredCustomerServices = filteredCustomerServices.filter(item => item.status === CustomerServiceStatus.completed);
        else filteredCustomerServices = filteredCustomerServices.filter(item => item.status !== CustomerServiceStatus.completed);
    }
    if (filters.readStatus !== 'all') {
        if (filters.readStatus === CustomerServiceStatus.notRead)
            filteredCustomerServices = filteredCustomerServices.filter(item => item.status === CustomerServiceStatus.notRead);
        else filteredCustomerServices = filteredCustomerServices.filter(item => item.status !== CustomerServiceStatus.notRead);
    }
    if (filters.blacklist !== 'all') {
        if (filters.blacklist === 'blacklisted')
            filteredCustomerServices = filteredCustomerServices.filter(item => item.User.status === 'blacklisted');
        else filteredCustomerServices = filteredCustomerServices.filter(item => item.User.status !== 'blacklisted');
    }
    return filteredCustomerServices;
}

export const formatDate = (moment: Moment, onlyDateOutput?: boolean) => {
    return moment.format(onlyDateOutput ? 'DD/MM/YYYY' : 'DD/MM/YYYY, HH:mm');
};

export const openApiLink = (link: string, queryParams: Record<string, string>) => {
    const linkTag: HTMLAnchorElement = document.createElement('a');
    const params = new URLSearchParams(queryParams);
    linkTag.target = '_blank';
    let baseUrl = env.reactAppSocketUrl;
    if (!env.production && window.location.host === 'localhost:3000') baseUrl = 'http://localhost:3001';
    linkTag.href = `${baseUrl}${env.apiUrl}/${link}?${params.toString()}`;
    linkTag.click();
};

export const getTodayStart = (): number => {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now.valueOf();
};

export const getDocumentStatus = (expTime: string | null, startingTime: string) => {
    const startingDate = new Date(new Date(startingTime).toISOString().split('T')[0] + 'T00:00:00.000Z').getTime();
    const todayDate = Date.now();

    if (startingDate >= todayDate) return 'programmed';
    if (!expTime) return 'ongoing';

    const expDate = new Date(expTime);

    if (isNaN(expDate.getTime())) return 'ongoing';

    const fixedDate = new Date(expDate.toISOString().split('T')[0] + 'T00:00:00.000Z').getTime();

    if (fixedDate >= todayDate) {
        return 'expiring';
    } else {
        return 'expired';
    }
};

export const timeStringToMilliseconds = (timeString: string) => {
    if (!timeString) return 0;
    const [hours, minutes] = timeString.split(':').map(Number);
    const hoursInMilliseconds = hours * 60 * 60 * 1000;
    const minutesInMilliseconds = minutes * 60 * 1000;
    const totalMilliseconds = hoursInMilliseconds + minutesInMilliseconds;

    return totalMilliseconds;
};

