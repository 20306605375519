import TariffAccordion from 'components/tariffAccordion/TariffAccordion';
import TariffAccordionTypography from 'components/tariffAccordionTypography/tariffAccordionTypography';
import { useEffect, useMemo } from 'react';
import classes from './ValidityPeriod.module.scss';
import ValidityPeriodButtons from './validityPeriodButtons/ValidityPeriodButtons';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_VALIDITY_SLOT, RootState, createTariffRuleActions } from 'store';
import ValidityPeriodItem from './validityPeriodItem/ValidityPeriodItem';
import Time from './time/Time';

const ValidityPeriod = () => {
    const { validityPeriod } = useSelector((x: RootState) => x.createTariffRule);
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const dispatch = useDispatch();

    const mappedTimeSlots = useMemo(
        () =>
            validityPeriod.slots.map((_date: any, index: number) => (
                <div className={classes.timeSlotsContainer} key={index}>
                    <p className="small lightBlue">
                        <b>Tipo</b>
                    </p>
                    <ValidityPeriodItem index={index} />
                </div>
            )),
        [validityPeriod]
    );

    useEffect(() => {
        if (!selectedRule) return;
        dispatch(
            createTariffRuleActions.setValidityPeriodValues({ key: 'repetitionType', value: selectedRule.repeatedPeriodType })
        );
        dispatch(
            createTariffRuleActions.setValidityPeriodValues({
                key: 'period',
                value: { startTime: selectedRule.startTime, endTime: selectedRule.endTime }
            })
        );
        dispatch(createTariffRuleActions.toggleAlwayValid(selectedRule.always));
        dispatch(createTariffRuleActions.toggleAllYears(selectedRule.repeatYears));
        const slots = selectedRule.ValidityPeriods.map((vp: any) => {
            return {
                period: { startDate: vp.startDay, endDate: vp.endDay },
                weekDay: {
                    textDay: vp.textDay,
                    weekNumber: vp.weekNumber,
                    month: vp.weekNumber ? vp.month : undefined,
                    year: vp.weekNumber ? vp.year : undefined
                },
                preset: {
                    beforeHoliday: vp.beforeHoliday,
                    holiday: vp.holiday,
                    year: vp.type === 'preset' ? vp.year : undefined
                },
                day: {
                    day: vp.day,
                    month: vp.weekNumber ? undefined : vp.month,
                    year: vp.weekNumber || vp.type === 'preset' ? undefined : vp.year
                },
                dayType: vp.weekNumber ? 'days' : 'day',
                type: vp.type === 'days' ? 'day' : vp.type
            };
        });
        dispatch(
            createTariffRuleActions.setValidityPeriodValues({
                key: 'slots',
                value: slots.length > 0 ? slots : [DEFAULT_VALIDITY_SLOT]
            })
        );
    }, [dispatch, selectedRule]);

    return (
        <TariffAccordion title="Periodo di validità" buttonTitle="Aggiungi" defaultOpen={!!selectedRule}>
            <ValidityPeriodButtons />
            <TariffAccordionTypography customClasses={classes.fieldOrganizerTypography}>
                <div className={classes.slotsWrapper}>{mappedTimeSlots}</div>
                <Time />
            </TariffAccordionTypography>
        </TariffAccordion>
    );
};

export default ValidityPeriod;

