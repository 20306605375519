import TextButton from 'components/buttons/textButton/TextButton';
import Modal from 'components/modal/Modal';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import classes from './ResetConfirmationModal.module.scss';
import { Area } from 'models';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import moment from 'moment';
import { formatDate } from 'helpers';

type ResetConfirmationModalProps = FC<{
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onClickHandler: () => void;
    selectedArea?: Area;
}>;

const ResetConfirmationModal: ResetConfirmationModalProps = ({ isOpen, setIsOpen, onClickHandler, selectedArea }) => {
    const { selectedMunicipality } = useSelector((state: RootState) => state.municipalities);
    const onCloseHandler = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);
    const handleOnClickHandler = useCallback(() => {
        onClickHandler();
        setIsOpen(false);
    }, [onClickHandler, setIsOpen]);

    const disableDates = useMemo(() => {
        if (!selectedArea) return;
        const startDate = moment(selectedArea.disabledFrom).tz(selectedMunicipality?.timezone || 'Europe/Rome');
        const endDate = moment(selectedArea.disabledTo).tz(selectedMunicipality?.timezone || 'Europe/Rome');
        return {
            startDate: formatDate(startDate, true),
            endDate: formatDate(endDate, true),
            startTime: startDate.format('HH:mm'),
            endTime: endDate.format('HH:mm')
        };
    }, [selectedArea, selectedMunicipality?.timezone]);

    return (
        <Modal
            className={classes.resetModal}
            title="Elimina inibizione programmmata"
            isOpen={isOpen}
            handleClose={onCloseHandler}
        >
            <div>
                Vuoi eliminare l’inibizione per l’area <b>{selectedArea && selectedArea.name}</b> programmata per il giorno{' '}
                <b>{disableDates?.startDate}</b> dalle ore <b>{disableDates?.startTime}</b> al giorno{' '}
                <b>{disableDates?.endDate} </b>
                alle ore <b>{disableDates?.endTime}</b>.
            </div>
            <div className={classes.buttons}>
                <TextButton onClick={onCloseHandler} className="secondary">
                    Annulla
                </TextButton>
                <TextButton onClick={handleOnClickHandler} className="error">
                    Elimina
                </TextButton>
            </div>
        </Modal>
    );
};

export default ResetConfirmationModal;

