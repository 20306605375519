import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { ParkingDetail, formatDate } from 'helpers';
import { FC, useMemo } from 'react';
import classes from './Parking.module.scss';
import moment from 'moment';

type ParkingProps = FC<{
    parkingInfo: ParkingDetail;
}>;

const LABELS = {
    car: 'Autoveicolo',
    motorbike: 'Motoveicolo',
    camper: 'Camper',
    bus: 'Bus',
    electricAuto: 'Auto elettrica',
    hybrid: 'Auto ibrida',
    gplAuto: 'Auto GPL'
};

const Parking: ParkingProps = ({ parkingInfo }) => {
    const parking = useMemo(
        () => (!parkingInfo.code && !parkingInfo.name ? '***' : `${parkingInfo.code} - ${parkingInfo.name}`),
        [parkingInfo]
    );
    const endingTime = useMemo(() => {
        if (!parkingInfo?.finishTime) return '';
        return moment(parkingInfo.finishTime).isBefore()
            ? formatDate(moment(parkingInfo.finishTime).tz(parkingInfo.timezone))
            : 'In corso';
    }, [parkingInfo]);

    if (!parkingInfo) return <></>;

    return (
        <div className={classes.accordionContainer}>
            <Accordion title="Area">
                <AccordionTypography>
                    <div>
                        <label className="small">
                            <b>Parcheggio</b>
                        </label>
                        <p className={classes.field}>{parking}</p>
                    </div>
                    <div>
                        <label className="small">
                            <b>Città - Timezone</b>
                        </label>
                        <p className={classes.field}>
                            {parkingInfo.city}
                            {parkingInfo.timezone ? ` - ${parkingInfo.timezone}` : null}
                        </p>
                    </div>
                    <div>
                        <label className="small">
                            <b>Targa Veicolo</b>
                        </label>
                        <p className={classes.field}>{parkingInfo.plate}</p>
                    </div>
                    <div>
                        <label className="small">
                            <b>Tipo veicolo</b>
                        </label>
                        <p className={classes.field}>{LABELS[parkingInfo.type]}</p>
                    </div>
                    <div>
                        <label className="small">
                            <b>Inizio Sosta</b>
                        </label>
                        <p className={classes.field}>{formatDate(moment(parkingInfo.startTime).tz(parkingInfo.timezone))}</p>
                    </div>
                    <div>
                        <label className="small">
                            <b>Fine Sosta</b>
                        </label>
                        <p className={classes.field}>{endingTime}</p>
                    </div>
                </AccordionTypography>
            </Accordion>
        </div>
    );
};

export default Parking;

