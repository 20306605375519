import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { store } from 'store';

export const simulatorApi = createApi({
    reducerPath: 'simulator-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        simulate: builder.query<
            any,
            { tariffId: string; duration: number; startTime: number; vehicleType: string; municipalityId: string }
        >({
            query: body => ({
                url: '/tariffs/simulate',
                method: 'POST',
                body
            })
        })
    })
});

export const { useLazySimulateQuery } = simulatorApi;

