import { FormControlLabel, Radio } from '@mui/material';
import TextButton from 'components/buttons/textButton/TextButton';
import { RadioChecked, RadioUnchecked } from 'components/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Modal from 'components/modal/Modal';
import GenericSelect from 'components/select/GenericSelect';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTariffRuleState, RootState, createTariffRuleActions } from 'store';
import classes from './DayModal.module.scss';
import DatePicker from 'react-datepicker';

const PERIOD = [
    { label: 'Primo', value: 1 },
    { label: 'Secondo', value: 2 },
    { label: 'Terzo', value: 3 },
    { label: 'Quarto', value: 4 },
    { label: 'Ultimo', value: 5 }
];
const DAYS = [
    { label: 'Lun', value: 1 },
    { label: 'Mar', value: 2 },
    { label: 'Mer', value: 3 },
    { label: 'Gio', value: 4 },
    { label: 'Ven', value: 5 },
    { label: 'Sab', value: 6 },
    { label: 'Dom', value: 7 }
];
const MONTHS = [
    { label: 'Gennaio', value: 1 },
    { label: 'Febbraio', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Aprile', value: 4 },
    { label: 'Maggio', value: 5 },
    { label: 'Giugno', value: 6 },
    { label: 'Luglio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Settembre', value: 9 },
    { label: 'Ottobre', value: 10 },
    { label: 'Novembre', value: 11 },
    { label: 'Dicembre', value: 12 }
];

type DayModalProps = FC<{ index: number; isOpen: boolean; onClose: (type: string) => void }>;

const DayModal: DayModalProps = ({ index, isOpen, onClose }) => {
    const { selectedRule } = useSelector((x: RootState) => x.tariffs);
    const { validityPeriod } = useSelector((x: RootState) => x.createTariffRule);
    const [dayType, setDayType] = useState<string>('day');
    const [day, setDay] = useState<number>(1);
    const [month, setMonth] = useState<number>(1);
    const [textDay, setTextDay] = useState<number>(1);
    const [weekNumber, setWeekNumber] = useState<number>(1);
    const [otherMonth, setOtherMonth] = useState<number>(1);
    const { allYears } = useSelector<RootState>(x => x.createTariffRule) as CreateTariffRuleState;
    const [startYearOne, setStartYearOne] = useState<Date>(new Date());
    const [startYearTwo, setStartYearTwo] = useState<Date>(new Date());
    const dispatch = useDispatch();

    const selectOptions = useMemo(() => {
        return PERIOD.map(option => ({
            id: option.value.toString(),
            label: option.label
        }));
    }, []);

    const daysSelectOptions = useMemo(() => {
        return DAYS.map(option => ({ id: option.value.toString(), label: option.label }));
    }, []);

    const monthsSelectOptions = useMemo(() => {
        return MONTHS.map(option => ({ id: option.value.toString(), label: option.label }));
    }, []);

    const canSubmit = useMemo(() => {
        return dayType === 'day'
            ? day && month && day > 0 && day < 32 && month > 0 && month < 13
            : textDay &&
                  weekNumber &&
                  month &&
                  textDay > 0 &&
                  textDay < 8 &&
                  weekNumber > 0 &&
                  weekNumber < 6 &&
                  month > 0 &&
                  month < 13;
    }, [day, dayType, month, textDay, weekNumber]);

    const onSaveHandler = () => {
        dispatch(createTariffRuleActions.setValidityPeriodSlot({ index, key: 'type', value: 'day' }));
        dispatch(
            createTariffRuleActions.setValidityPeriodSlot({
                index,
                key: 'dayType',
                value: dayType
            })
        );
        onClose('day');
        if (dayType === 'day')
            return dispatch(
                createTariffRuleActions.setValidityPeriodSlot({
                    index,
                    key: 'day',
                    value: { day, month, year: startYearOne.getFullYear() }
                })
            );
        dispatch(
            createTariffRuleActions.setValidityPeriodSlot({
                index,
                key: 'weekDay',
                value: { textDay, weekNumber, month: otherMonth, year: startYearTwo.getFullYear() }
            })
        );
        onClose('day');
    };

    const onSelectHandlerYearPickerOne = (value: number) => {
        if (!value) return;
        setStartYearOne(new Date(value));
    };

    const onSelectHandlerYearPickerTwo = (value: number) => {
        if (!value) return;
        setStartYearTwo(new Date(value));
    };

    // TODO explain logic here and why weekNumber > 1 and not > 0
    useEffect(() => {
        if (!selectedRule || !selectedRule.ValidityPeriods[index]) return;
        setDayType(selectedRule.ValidityPeriods[index].weekNumber > 1 ? 'days' : 'day');
        setDay(selectedRule.ValidityPeriods[index].day);
        setMonth(selectedRule.ValidityPeriods[index].weekNumber > 1 ? 1 : selectedRule.ValidityPeriods[index].month);
        setTextDay(selectedRule.ValidityPeriods[index].textDay);
        setWeekNumber(selectedRule.ValidityPeriods[index].weekNumber);
        setOtherMonth(selectedRule.ValidityPeriods[index].weekNumber > 1 ? selectedRule.ValidityPeriods[index].month : 1);
    }, [index, selectedRule]);

    useEffect(() => {
        if (!isOpen) return;
        setDayType(validityPeriod.slots[index].dayType ?? 'day');
        setDay(validityPeriod.slots[index].day.day ?? 1);
        setMonth(validityPeriod.slots[index].day.month ?? 1);
        setTextDay(validityPeriod.slots[index].weekDay.textDay ?? 1);
        setWeekNumber(validityPeriod.slots[index].weekDay.weekNumber ?? 1);
        setOtherMonth(validityPeriod.slots[index].weekDay.month ?? 1);
    }, [index, isOpen, validityPeriod.slots]);

    const daysNumberSelectOptions = useMemo(() => {
        const isLeapYear = (year: number): boolean => {
            return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        };

        const getDaysInMonth = (month: number, year: number) => {
            switch (+month) {
                case 2:
                    return isLeapYear(year) ? 29 : 28;
                case 4:
                case 6:
                case 9:
                case 11:
                    return 30;
                default:
                    return 31;
            }
        };

        const daysInMonth = getDaysInMonth(month, new Date().getFullYear());
        return Array.from(Array(daysInMonth).keys()).map(day => ({ id: day + 1, label: day + 1 }));
    }, [month]);

    return (
        <Modal customClasses={classes.modalContainer} isOpen={isOpen} handleClose={() => onClose('')} title="Ripetizione annuale">
            <div className={classes.distance}>
                <div className={classes.days}>
                    <FormControlLabel
                        value="day"
                        onClick={(event: any) => setDayType(event.target.value)}
                        checked={dayType === 'day'}
                        control={<Radio checkedIcon={<RadioChecked />} icon={<RadioUnchecked />} />}
                        label="Il giorno"
                    />
                    <GenericSelect
                        disabled={dayType === 'days'}
                        value={day}
                        onChange={(event: any) => setDay(event.target.value)}
                        options={daysNumberSelectOptions.map(option => ({
                            id: option.id.toString(),
                            label: option.label.toString().length > 1 ? option.label.toString() : '0' + option.label.toString()
                        }))}
                    />
                </div>
            </div>
            <div className={classes.distance}>
                <div className={classes.monthForm}>
                    <label className={classes.labelDistance}>del mese</label>
                    <GenericSelect
                        disabled={dayType === 'days'}
                        value={month}
                        onChange={(event: any) => setMonth(event.target.value)}
                        options={monthsSelectOptions}
                    />
                </div>
                <div className={classes.monthForm}>
                    <label className={classes.labelDistance}>dell&apos;anno</label>
                    <div className={classes.datePickerContainer}>
                        <DatePicker
                            disabled={allYears || dayType === 'days'}
                            showYearPicker
                            dateFormat="yyyy"
                            wrapperClassName="datePicker"
                            selected={startYearOne}
                            onChange={date => onSelectHandlerYearPickerOne(date?.getTime()!)}
                        />
                        <KeyboardArrowDownIcon />
                    </div>
                </div>
            </div>
            <div className={classes.distance}>
                <div className={classes.numberAndDateForm}>
                    <FormControlLabel
                        onClick={(event: any) => setDayType(event.target.value)}
                        value="days"
                        checked={dayType === 'days'}
                        control={<Radio checkedIcon={<RadioChecked />} icon={<RadioUnchecked />} />}
                        label="Il"
                    />
                    <GenericSelect
                        value={weekNumber}
                        disabled={dayType === 'day'}
                        onChange={(event: any) => setWeekNumber(event.target.value)}
                        options={selectOptions}
                    />
                    <GenericSelect
                        disabled={dayType === 'day'}
                        value={textDay}
                        onChange={(event: any) => setTextDay(event.target.value)}
                        options={daysSelectOptions}
                    />
                </div>
            </div>
            <div className={classes.distance}>
                <div className={classes.monthForm}>
                    <label className={classes.labelDistance}>del mese</label>
                    <GenericSelect
                        disabled={dayType === 'day'}
                        value={otherMonth}
                        onChange={(event: any) => setOtherMonth(event.target.value)}
                        options={monthsSelectOptions}
                    />
                </div>
                <div className={classes.monthForm}>
                    <label className={classes.labelDistance}>dell&apos;anno</label>
                    <div className={classes.datePickerContainer}>
                        <DatePicker
                            disabled={allYears || dayType === 'day'}
                            showYearPicker
                            dateFormat="yyyy"
                            wrapperClassName="datePicker"
                            selected={startYearTwo}
                            onChange={date => onSelectHandlerYearPickerTwo(date?.getTime()!)}
                        />
                        <KeyboardArrowDownIcon />
                    </div>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <TextButton className={`secondary ${classes.customButton}`} onClick={() => onClose('')}>
                    Annulla
                </TextButton>
                <TextButton onClick={onSaveHandler} className={`primary ${classes.customButton}`} disabled={!canSubmit}>
                    Salva
                </TextButton>
            </div>
        </Modal>
    );
};

export default DayModal;

