import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { Geometry } from 'helpers';
import { Area, AreaTariff, AreasTableItem } from 'models';
import { store } from 'store';

interface AreaCreation {
    name: string;
    street: string;
    geometry: any;
    municipalityId: string;
    serviceId: string;
}

interface AreaUpdate {
    id: string;
    name: string;
    code: string;
    geometry: Geometry;
    parkingSpaces: string;
    tariffId: string;
}

interface DashboardInfo {
    totalAmount: number;
    parkingNumber: number;
    totalSpaces: number;
    totalAreas: number;
}

interface DashboardDetailInfo {
    parkingSpaces: number;
    totalParking: string;
    totalAmount: number;
}

export interface GetFilterParams {
    searchedWord: string;
    city: string | null;
    date: string;
    inProgress: string;
    offset?: number;
    pageSize?: number;
    orderBy?: string;
}

export const areaApi = createApi({
    reducerPath: 'area-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getAreaById: builder.query<AreasTableItem, { id: string }>({
            query: params => ({
                url: `/areas/${params.id}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 30
        }),
        getAreasByNameOrCode: builder.query<Area[], { searchedWord: string }>({
            query: params => ({
                url: '/areas/by-code-or-street',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        getAreasDashboardInfo: builder.query<DashboardInfo, GetFilterParams>({
            query: params => ({
                url: '/areas/dashboard-info',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        getAreaDetailDashboardInfo: builder.query<DashboardDetailInfo, { id: string }>({
            query: params => ({
                url: '/areas/detail-dashboard-info',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        getAllAreas: builder.query<AreasTableItem[], GetFilterParams>({
            query: params => ({
                url: '/areas',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        getAllAreaByMunicipality: builder.query<
            AreaTariff[],
            { municipalityId: string; searchedArea: string; inProgress: string; status: string }
        >({
            query: params => ({
                url: '/areas/by-municipality',
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 1
        }),
        createArea: builder.query<Area, { areaCreation: AreaCreation }>({
            query: body => ({
                url: '/areas',
                method: 'POST',
                body
            })
        }),
        editArea: builder.query<Area, { areaUpdate: AreaUpdate }>({
            query: body => ({
                url: '/areas/update',
                method: 'POST',
                body
            })
        }),
        updateAreaStatus: builder.query<Area, { id: string; isDisabled: boolean; isDisabledFor: string }>({
            query: body => ({
                url: '/areas/status',
                method: 'PUT',
                body
            })
        }),
        scheduleAreaStatus: builder.query<
            Area,
            { id: string; isDisabled: boolean; isDisabledFor: string; startDate: string; endDate: string }
        >({
            query: body => ({
                url: '/areas/schedule-status',
                method: 'PUT',
                body
            })
        }),
        deleteArea: builder.mutation<Area, { areaId: string }>({
            query: body => ({
                url: '/areas',
                method: 'DELETE',
                body
            })
        }),
        resetSchedule: builder.mutation<Area, { areaId: string }>({
            query: params => ({
                url: `/areas/status/reset-schedule/${params.areaId}`,
                method: 'PUT'
            })
        })
    })
});

export const {
    useGetAreaByIdQuery,
    useLazyGetAreaByIdQuery,
    useGetAreasDashboardInfoQuery,
    useLazyGetAreasByNameOrCodeQuery,
    useGetAreaDetailDashboardInfoQuery,
    useLazyCreateAreaQuery,
    useGetAllAreasQuery,
    useGetAllAreaByMunicipalityQuery,
    useLazyEditAreaQuery,
    useLazyUpdateAreaStatusQuery,
    useLazyScheduleAreaStatusQuery,
    useDeleteAreaMutation,
    useResetScheduleMutation
} = areaApi;

