import { GridColDef } from '@mui/x-data-grid';
import { IconButton, IconTextButton } from 'components/buttons';
import GridTable from 'components/gridTable/GridTable';
import {
    ArrowBack,
    ArrowDown,
    ArrowUp,
    Delete,
    Denied,
    Edit,
    Free,
    Payment,
    Plus,
    SlopeReprise,
    SpreadsheetsFriend,
    UsersCounter
} from 'components/icons';
import { usePermissionHandler } from 'hook';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, createTariffRuleActions, tariffActions } from 'store';
import { useGetTariffsByMunicipalityQuery } from 'store/rtk/tariff.service';
import { useLazyGetTariffRuleByIdQuery, useLazyUpdateTariffRuleCardinalityQuery } from 'store/rtk/tariffRule.service';
import colors from 'styles/modules/colors.module.scss';
import classes from './TariffRuleList.module.scss';
import DeleteTariffModal from './deleteTariffModal/DeleteTariffModal';
import EditTariffModal from './editTariffModal/EditTariffModal';

const TariffRuleList = () => {
    const { selectedMunicipality } = useSelector((x: RootState) => x.municipalities);
    const { selectedTariff } = useSelector((x: RootState) => x.tariffs);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 20, page: 0 });
    const onCreateEditHandler = () => dispatch(tariffActions.toggleCreateEditModalOpen());
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const hasPermission = usePermissionHandler();
    const [getTariffRule] = useLazyGetTariffRuleByIdQuery();
    const [updateCardinality, { isFetching }] = useLazyUpdateTariffRuleCardinalityQuery();
    const { refetch, isFetching: isRefetching } = useGetTariffsByMunicipalityQuery({ municipalityId: selectedMunicipality?.id! });
    const dispatch = useDispatch();

    const tariffRules = useMemo(() => {
        if (!selectedTariff?.TariffRules) return [];
        return selectedTariff.TariffRules.slice().sort((a, b) => a.cardinality - b.cardinality);
    }, [selectedTariff?.TariffRules]);

    const createRuleHandler = useCallback(() => {
        dispatch(tariffActions.setSelectedTariffRule(undefined));
        dispatch(createTariffRuleActions.resetCreation());
        dispatch(tariffActions.setIsCreatingRule(true));
        dispatch(tariffActions.setIsEditingRule(false));
        dispatch(tariffActions.setIsTariffSimulator(false));
    }, [dispatch]);

    const openTariffSimulator = useCallback(() => {
        dispatch(tariffActions.setSelectedTariffRule(undefined));
        dispatch(createTariffRuleActions.resetCreation());
        dispatch(tariffActions.setIsCreatingRule(false));
        dispatch(tariffActions.setIsEditingRule(false));
        dispatch(tariffActions.setIsTariffSimulator(true));
    }, [dispatch]);

    const HEADERS: GridColDef[] = useMemo(() => {
        const switchPositionHandler = (index: number, direction: number) => async (event: any) => {
            event.stopPropagation();
            const rulesToUpdate = [
                { id: tariffRules[index].id, cardinality: index + direction },
                { id: tariffRules[index + direction].id, cardinality: index }
            ];
            await updateCardinality({ tariffRules: rulesToUpdate });
            const data = await refetch();
            const updatedTariff = data.data?.find(tariff => tariff.id === selectedTariff?.id);
            dispatch(tariffActions.setSelectedTariff(updatedTariff));
            createRuleHandler();
            dispatch(tariffActions.setIsCreatingRule(false));
        };

        const sortingButtons = (row: any) => {
            const index = tariffRules.findIndex(obj => obj.id === row.row.id);

            return (
                <div className={classes.sortingButtons}>
                    <IconButton
                        disabled={index === tariffRules.length - 1 || isFetching || isRefetching}
                        icon={
                            <ArrowDown
                                fill={
                                    index === tariffRules.length - 1 || isFetching || isRefetching
                                        ? colors.primary40
                                        : colors.primary90
                                }
                            />
                        }
                        onClick={switchPositionHandler(index, 1)}
                        className="tertiary"
                    />
                    <IconButton
                        disabled={index === 0 || isFetching || isRefetching}
                        icon={<ArrowUp fill={index === 0 || isFetching || isRefetching ? colors.primary40 : colors.primary90} />}
                        onClick={switchPositionHandler(index, -1)}
                        className="tertiary"
                    />
                </div>
            );
        };

        const rateType = (row: any) => {
            return (
                <div>
                    {row.row.rateType === 'free' ? (
                        <Free />
                    ) : row.row.rateType === 'blocking' ? (
                        <Denied />
                    ) : row.row.rateType === 'slopes' ? (
                        <Payment />
                    ) : null}
                </div>
            );
        };

        const vehicles = (row: any) => {
            const counter = row.row.VehicleTypes.length > 0 ? row.row.VehicleTypes.length : 0;
            return <UsersCounter value={counter} />;
        };

        const time = (row: any) => {
            const startTime = row.row.startTime;
            const endTime = row.row.endTime;
            return `${startTime}→${endTime}`;
        };

        return [
            { field: 'name', width: 120, sortable: false, headerName: 'Nome regola' },
            { field: 'rateType', width: 80, sortable: false, headerName: 'Tipo', renderCell: rateType },
            { field: 'vehicles', width: 90, sortable: false, headerName: 'Veicoli', renderCell: vehicles },
            { field: 'time', width: 90, sortable: false, headerName: 'Orario', renderCell: time },
            { field: '', width: 90, sortable: false, renderCell: sortingButtons }
        ];
    }, [createRuleHandler, dispatch, refetch, selectedTariff?.id, tariffRules, updateCardinality, isFetching, isRefetching]);

    const onClickHandler = () => {
        dispatch(tariffActions.setIsCreatingRule(false));
        dispatch(tariffActions.setIsEditingRule(false));
        dispatch(tariffActions.setSelectedTariff(undefined));
    };

    const onRowSelect = async ({ row }: any) => {
        const { data: rule } = await getTariffRule({ id: row.id });
        if (!rule) return;
        dispatch(tariffActions.setSelectedTariffRule(rule));
        dispatch(tariffActions.setIsEditingRule(true));
        dispatch(tariffActions.setIsCreatingRule(false));
    };

    if (!selectedMunicipality || !selectedTariff) return <></>;

    return (
        <div>
            <div className={classes.header}>
                <div className={classes.back} onClick={onClickHandler}>
                    <IconButton icon={<ArrowBack />} onClick={onClickHandler} className="tertiary" />
                    <p className={classes.title}>
                        <span className="primary60">{selectedMunicipality.name} / </span>
                        {selectedTariff.slopeReprise ? <SlopeReprise /> : <></>}
                        Tariffa - {selectedTariff.name}
                    </p>
                </div>
                <div className={classes.actions}>
                    <IconButton
                        disabled={!hasPermission(TabTypes.municipalitySettings, ['can-edit'])}
                        icon={<Edit />}
                        onClick={() => onCreateEditHandler()}
                        className={`tertiary ${classes.btn}`}
                    />
                    <IconButton
                        disabled={!hasPermission(TabTypes.municipalitySettings, ['can-delete'])}
                        icon={<Delete />}
                        onClick={() => setIsDeleteOpen(true)}
                        className={`tertiary ${classes.btn}`}
                    />
                </div>
            </div>
            <div className={classes.grid}>
                {tariffRules?.length > 0 ? (
                    <GridTable
                        rowCount={tariffRules.length}
                        headers={HEADERS}
                        rows={tariffRules}
                        paginationModel={paginationModel}
                        onRowSelection={onRowSelect}
                        onPaginationModelChange={setPaginationModel}
                    />
                ) : (
                    <div className={classes.noresults}>
                        <SpreadsheetsFriend />
                        Nessun risultato.
                    </div>
                )}
                <div className={classes.buttons}>
                    <IconTextButton className={`primary ${classes.createBtn}`} icon={<Plus />} onClick={openTariffSimulator}>
                        Simulatore tariffa
                    </IconTextButton>
                    <IconTextButton
                        disabled={!hasPermission(TabTypes.municipalitySettings, ['can-create'])}
                        className={`primary ${classes.createBtn}`}
                        icon={<Plus />}
                        onClick={createRuleHandler}
                    >
                        Aggiungi Regola
                    </IconTextButton>
                </div>
                <EditTariffModal selectedTariff={selectedTariff} />
            </div>
            <DeleteTariffModal isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} />
        </div>
    );
};
export default TariffRuleList;

