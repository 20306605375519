import { FC } from 'react';
import classes from '../ModalEnableArea.module.scss';
import Modal from 'components/modal/Modal';
import TextButton from 'components/buttons/textButton/TextButton';

type ConfirmDisableModalProps = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: () => void;
};
const ConfirmDisableModal: FC<ConfirmDisableModalProps> = ({ isOpen, setIsOpen, onSubmit }) => {
    return (
        <Modal className={classes.modalArea} title="Soste in corso" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
            Sono presenti delle soste in corso per questa area durante il periodo scelto. Continuando la disabilitazione dell'area
            verranno terminate tutte le soste.
            <div className={classes.buttons}>
                <TextButton onClick={() => setIsOpen(false)} className="secondary">
                    Annulla
                </TextButton>
                <TextButton onClick={onSubmit} className="primary">
                    Conferma
                </TextButton>
            </div>
        </Modal>
    );
};

export default ConfirmDisableModal;

