import { LinearProgress } from '@mui/material';
import { DataGrid, GridColDef, GridSortDirection, GridToolbar } from '@mui/x-data-grid';
import { FC, useEffect, useMemo } from 'react';
import classes from './GridTable.module.scss';
import NoResult from 'components/noResult/NoResult';
import { SetState } from 'helpers';

type GridTableProps = FC<{
    headers: any;
    rows: any[];
    enableToolbar?: boolean;
    enableSearchBar?: boolean;
    onRowSelection?: any;
    searchedWord?: string[];
    setSearchedWord?: any;
    isFetching?: boolean;
    onPaginationModelChange?: (...data: any) => any;
    paginationModel: { pageSize: number; page: number };
    rowCount?: number;
    getRowClassName?: any;
    setOrderBy?: SetState<{ column: string; type: string }>;
    sortModel?: { column: string; type: string };
    defaultSortColumn?: string;
    sortingMode?: 'client' | 'server';
}>;

const GridTable: GridTableProps = ({
    headers,
    rows,
    enableToolbar = false,
    enableSearchBar = false,
    onRowSelection,
    setSearchedWord,
    isFetching,
    searchedWord = [],
    onPaginationModelChange = () => {},
    paginationModel = { pageSize: 20, page: 0 },
    rowCount,
    getRowClassName,
    setOrderBy,
    sortModel,
    defaultSortColumn = '',
    sortingMode = 'server'
}) => {
    const slots = {
        toolbar: enableToolbar ? GridToolbar : undefined,
        loadingOverlay: LinearProgress,
        noRowsOverlay: () => <NoResult />,
        noResultsOverlay: () => <NoResult />
    };

    const slotsProps = enableSearchBar
        ? {
              toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
              },
              pagination: {
                  labelRowsPerPage: 'Righe per pagina',
                  labelDisplayedRows: ({ from, to, count }: any) => `${from} - ${to} di ${count}`
              }
          }
        : {
              pagination: {
                  labelRowsPerPage: 'Righe per pagina',
                  labelDisplayedRows: ({ from, to, count }: any) => `${from} - ${to} di ${count}`
              }
          };

    const columns: GridColDef[] = useMemo(
        () =>
            headers.map((header: any) => ({
                ...header,
                width: header.width ?? 180,
                editable: false
            })),
        [headers]
    );

    useEffect(() => {
        if (rowCount !== undefined) onPaginationModelChange((prev: any) => ({ ...prev, page: 0 }));
    }, [onPaginationModelChange, rowCount]);

    const onSortModelChangeHandler = (values: any) => {
        if (!setOrderBy) return;
        if (!values) setOrderBy({ column: defaultSortColumn, type: 'asc' });
        else setOrderBy({ column: values.field, type: values.sort });
    };

    const sortBy = useMemo(
        () =>
            !sortModel || sortModel?.column === ''
                ? undefined
                : [{ field: sortModel?.column as string, sort: sortModel?.type as GridSortDirection }],
        [sortModel]
    );

    return (
        <div className={`${classes.dataGrid}`}>
            <DataGrid
                onFilterModelChange={event => setSearchedWord && setSearchedWord(event.quickFilterValues)}
                filterModel={{ quickFilterValues: searchedWord, items: [] }}
                columns={columns}
                slots={slots}
                slotProps={slotsProps}
                sortingMode={sortingMode}
                rows={rows}
                getRowId={row => row.id}
                onRowClick={onRowSelection}
                onPaginationModelChange={onPaginationModelChange}
                onSortModelChange={values => onSortModelChangeHandler(values[0])}
                sortModel={sortBy}
                paginationModel={paginationModel}
                pageSizeOptions={[20, 50, 100]}
                loading={isFetching}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                rowCount={+(rowCount ?? 0)}
                rowHeight={56}
                getRowClassName={getRowClassName ?? (() => '')}
                disableColumnMenu={true}
                disableColumnFilter={true}
                disableDensitySelector={true}
                localeText={{
                    toolbarQuickFilterLabel: 'Ricerca...',
                    toolbarQuickFilterPlaceholder: 'Ricerca...'
                }}
                disableColumnSelector={true}
                pagination
                paginationMode="server"
            />
        </div>
    );
};

export default GridTable;

