import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'environments';
import { Rule } from 'helpers';
import { store } from 'store';

export const tariffRuleApi = createApi({
    reducerPath: 'tariffs-rule-api',
    baseQuery: fetchBaseQuery({
        fetchFn(input, init) {
            const authState = store.getState().auth.token as any;
            return fetch(input, { ...init, headers: { authorization: authState!, 'content-type': 'application/json' } });
        },
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}`
    }),
    endpoints: builder => ({
        getTariffRuleById: builder.query<Rule, { id: string }>({
            query: params => ({
                url: '/tariff-rules',
                method: 'get',
                params
            })
        }),
        createTariffRule: builder.query<any, { tariffRule: any; slopes: any; vehicleTypeIds: any; validityPeriods: any }>({
            query: body => ({
                url: '/tariff-rules',
                method: 'POST',
                body
            })
        }),
        updateTariffRule: builder.query<any, { tariffRule: any; slopes: any; vehicleTypeIds: any; validityPeriods: any }>({
            query: body => ({
                url: '/tariff-rules/edit',
                method: 'POST',
                body
            })
        }),
        updateTariffRuleCardinality: builder.query<any, { tariffRules: any[] }>({
            query: body => ({
                url: '/tariff-rules/cardinality',
                method: 'PUT',
                body
            })
        }),
        getPresetDays: builder.query<any, void>({
            query: () => ({
                url: '/tariff-rules/preset',
                method: 'GET'
            })
        }),
        deleteTariffRule: builder.query<any, { id: string }>({
            query: params => ({
                url: '/tariff-rules',
                method: 'DELETE',
                params
            })
        })
    })
});

export const {
    useLazyGetTariffRuleByIdQuery,
    useLazyCreateTariffRuleQuery,
    useLazyUpdateTariffRuleQuery,
    useLazyUpdateTariffRuleCardinalityQuery,
    useGetPresetDaysQuery,
    useLazyDeleteTariffRuleQuery
} = tariffRuleApi;

