import Card from 'components/card/Card';
import { Conversation } from 'components/icons';
import { CustomerServiceItem, CustomerServiceStatus, formatDate } from 'helpers';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, customerServiceActions } from 'store';
import { useLazyUpdateCustomerServiceStatusQuery } from 'store/rtk/customerService.service';
import classes from './CustomerServiceDetails.module.scss';
import UserInfo from './userInfo/UserInfo';
import { usePermissionHandler } from 'hook';
import moment from 'moment';

type CustomerServiceDetailsProps = FC<{
    setFilteredCustomerServices: Dispatch<SetStateAction<CustomerServiceItem[]>>;
    areFiltersOpen: boolean;
}>;

const CustomerServiceDetails: CustomerServiceDetailsProps = ({ setFilteredCustomerServices, areFiltersOpen }) => {
    const { selectedCustomerService } = useSelector((x: RootState) => x.customerService);
    const dispatch = useDispatch();
    const hasPermission = usePermissionHandler();
    const [updateCustomerServiceStatus] = useLazyUpdateCustomerServiceStatusQuery();

    const onCustomerServiceStatusUpdate = useCallback(
        async (id: string, selected: CustomerServiceItem | undefined) => {
            const newStatus =
                selected?.status === CustomerServiceStatus.read ? CustomerServiceStatus.completed : CustomerServiceStatus.read;
            const { data } = await updateCustomerServiceStatus({ id, status: newStatus });
            if (!data) return;
            setFilteredCustomerServices(prev => {
                const index = prev.findIndex(item => item.id === data.id);
                if (index >= 0) {
                    const updatedItem = { ...prev[index], status: data.status };
                    const updatedArray = [...prev];
                    updatedArray[index] = updatedItem;
                    return updatedArray;
                }
                return prev;
            });
            dispatch(customerServiceActions.updateCustomerServiceStatus(data));
            dispatch(customerServiceActions.updateSelectedCustomerServiceStatus({ status: newStatus, id }));
        },
        [dispatch, setFilteredCustomerServices, updateCustomerServiceStatus]
    );

    if (!areFiltersOpen && !selectedCustomerService)
        return (
            <div className={classes.emptyContainer}>
                <Conversation />
            </div>
        );

    return (
        <div className={classes.container}>
            <UserInfo />
            <div className={classes.card}>
                <Card
                    onChange={() => onCustomerServiceStatusUpdate(selectedCustomerService?.id!, selectedCustomerService)}
                    checkBox
                    checked={selectedCustomerService?.status === CustomerServiceStatus.completed}
                    disabled={!hasPermission(TabTypes.customerService, ['can-edit'])}
                >
                    <b>Segna la richiesta come evasa</b>
                </Card>
            </div>
            <div className={classes.message}>
                <span className={classes.date}>
                    {selectedCustomerService?.createdAt && formatDate(moment(selectedCustomerService.createdAt))}
                </span>
                <span className={classes.detail}>{selectedCustomerService?.description}</span>
            </div>
        </div>
    );
};

export default CustomerServiceDetails;

