import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { IconTextButton } from 'components/buttons/';
import { CircleEmpty } from 'components/icons/CircleEmpty';
import { Invoice } from 'components/icons/Invoce';
import { ParkingDetail, formatDate } from 'helpers';
import { Billing as BillingInterface, BillingStatus, UserRoles } from 'models';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, RootState, TabTypes, tabActions } from 'store';
import { billingsActions } from 'store/billing.slice';
import classes from './Billing.module.scss';
import { CircleFilled } from 'components/icons';
import moment from 'moment';

type ParkingData = {
    ticket: ParkingDetail;
    companyInformations: BillingInterface;
};

type BillingProps = FC<{
    data: ParkingData;
}>;

const Billing: BillingProps = ({ data }) => {
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();

    const onClickHandler = () => {
        dispatch(billingsActions.setSelectedBilling(data.companyInformations));
        dispatch(tabActions.setActiveTab(TabTypes.billing));
    };

    return (
        <div className={classes.accordionContainer}>
            <Accordion title="Fatturazione">
                <AccordionTypography>
                    <div className={classes.fieldButton}>
                        <div className={classes.billingInfo}>
                            {data.ticket.billingStatus === BillingStatus.pending ? <CircleEmpty /> : <CircleFilled />}
                            <label className="small">
                                {data.ticket.billingStatus === BillingStatus.pending ? 'Fattura da evadere' : 'Fattura evasa'}
                            </label>
                        </div>
                    </div>
                    <div>
                        <label className="small">
                            {data.ticket.billingStatus === BillingStatus.pending ? (
                                <b>Data richiesta fattura</b>
                            ) : (
                                <b>Fattura presa in carico</b>
                            )}
                        </label>
                        <p className={classes.field}>{formatDate(moment(data.ticket.updatedAt).tz(data.ticket.timezone))}</p>
                    </div>
                    <div className={classes.fieldButton}>
                        <div>
                            <label className="small">
                                <IconTextButton
                                    disabled={user?.role === UserRoles.municipalityEmployee}
                                    className={`secondaryVariant`}
                                    icon={<Invoice />}
                                    onClick={onClickHandler}
                                >
                                    Vedi fatture
                                </IconTextButton>
                            </label>
                        </div>
                    </div>
                </AccordionTypography>
            </Accordion>
        </div>
    );
};

export default Billing;

