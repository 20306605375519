import { IconTextButton } from 'components/buttons';
import Card from 'components/card/Card';
import { Area, Plus, TariffChip } from 'components/icons';
import NoResult from 'components/noResult/NoResult';
import { usePermissionHandler } from 'hook';
import { MunicipalityCounter } from 'models';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TabTypes, drawActions, municipalityActions } from 'store';
import { useGetAllMunicipalitiesQuery } from 'store/rtk/municipality.service';
import classes from './MunicipalityList.module.scss';
import CreateMunicipalityModal from './createMunicipalityModal/CreateMunicipalityModal';
import MunicipalitySearchbar from './municipalitySearchbar/MunicipalitySearchbar';

const AreaList: FC = () => {
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [searchedWord, setSearchedWord] = useState<string>('');
    const dispatch = useDispatch();
    const hasPermission = usePermissionHandler();
    const { data: municipalities, refetch: refetchMunicipalities } = useGetAllMunicipalitiesQuery();

    const municipalityList = useMemo(() => {
        if (!municipalities || municipalities.length === 0) return [];
        const filteredMunicipalities =
            searchedWord !== ''
                ? municipalities.filter(v => v.name.toLowerCase().includes(searchedWord.toLowerCase()))
                : municipalities;
        return filteredMunicipalities.slice(0, Math.min(100, filteredMunicipalities.length));
    }, [municipalities, searchedWord]);

    const createMunicipalityHandler = () => setCreateModalOpen(true);

    const onCardClickHandler = (municipality: MunicipalityCounter) => () => {
        dispatch(municipalityActions.setSelectedMunicipality(municipality));
        dispatch(drawActions.zoomOnBbox(municipality.bbox));
    };

    useEffect(() => {
        if (!municipalities) return;
        dispatch(municipalityActions.setMunicipalities(municipalities));
    }, [municipalities, dispatch]);

    useEffect(() => {
        refetchMunicipalities();
    }, [refetchMunicipalities]);

    const searchBarHandler = (searchedWord: string) => {
        setSearchedWord(searchedWord);
    };

    return (
        <>
            <MunicipalitySearchbar searchBarHandler={searchBarHandler as any} searchedWord={searchedWord} />
            <div className={classes.areaList}>
                {municipalityList.length !== 0 ? (
                    <div className={classes.cardList}>
                        {municipalityList.map(municipality => (
                            <Card
                                key={municipality.id}
                                chips={[
                                    { icon: <Area />, label: municipality.numberAreas },
                                    { icon: <TariffChip />, label: municipality.numberTariff }
                                ]}
                                onClick={onCardClickHandler(municipality)}
                            >
                                {municipality.name}
                            </Card>
                        ))}
                    </div>
                ) : (
                    <NoResult />
                )}
                <IconTextButton
                    disabled={!hasPermission(TabTypes.municipalitySettings, ['can-create'])}
                    className={`primary ${classes.createBtn}`}
                    icon={<Plus />}
                    onClick={createMunicipalityHandler}
                >
                    Aggiungi città
                </IconTextButton>
                <CreateMunicipalityModal
                    isOpen={isCreateModalOpen}
                    setIsOpen={setCreateModalOpen}
                    refetch={refetchMunicipalities}
                />
            </div>
        </>
    );
};

export default AreaList;

