import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import classes from './SelectLabel.module.scss';

type SelectLabelProps = {
    index: number;
};

const WEEK_NUMBER: { [key: number]: string } = {
    1: 'Il primo',
    2: 'Il secondo',
    3: 'Il terzo',
    4: 'Il quarto',
    5: "L'ultimo"
};

const MONTH: { [key: number]: string } = {
    1: 'Gennaio',
    2: 'Febbraio',
    3: 'Marzo',
    4: 'Aprile',
    5: 'Maggio',
    6: 'Giugno',
    7: 'Luglio',
    8: 'Agosto',
    9: 'Settembre',
    10: 'Ottobre',
    11: 'Novembre',
    12: 'Dicembre'
};

const TEXT_DAY: { [key: number]: string } = {
    1: 'Lun',
    2: 'Mar',
    3: 'Mer',
    4: 'Gio',
    5: 'Ven',
    6: 'Sab',
    7: 'Dom'
};

const SelectLabel: FC<SelectLabelProps> = ({ index }) => {
    const { validityPeriod, allYears } = useSelector((state: RootState) => state.createTariffRule);
    const labelValue = useMemo(() => {
        const slot = validityPeriod.slots[index];
        if (!slot) return null;

        const { type } = slot;
        switch (type) {
            case 'period':
                const startDate = slot.period?.startDate;
                const endDate = slot.period?.endDate;
                if (!startDate || !endDate) return null;

                const formattedStartDate = new Date(startDate);
                const tmp = new Date(endDate);
                tmp.setDate(0);
                const formattedEndDate = new Date(endDate);
                return `Dal ${formattedStartDate.getDate()}/${formattedStartDate.getMonth() + 1}${
                    allYears ? '' : `/${formattedStartDate.getFullYear()}`
                } al ${formattedEndDate.getDate() > 1 ? formattedEndDate.getDate() - 1 : tmp.getDate()}/${
                    formattedEndDate.getMonth() + (formattedEndDate.getDate() > 1 ? 1 : 0) || 12
                }${allYears ? '' : `/${formattedEndDate.getFullYear() ?? ''}`}`;

            case 'preset':
                const { beforeHoliday, holiday } = slot.preset!;
                return `${beforeHoliday ? 'Prefestivi' : ''}${beforeHoliday && holiday ? ', ' : ''}${holiday ? 'Festivi' : ''}${
                    allYears ? '' : ` ${validityPeriod.slots[index].preset.year ?? ''}`
                }`;

            case 'day':
                const { day, dayType, weekDay } = slot;
                if (dayType === 'day') {
                    return `Il ${day!.day}/${day!.month?.toString().padStart(2, '0')}${allYears ? '' : `/${day!.year ?? ''}`}`;
                } else if (dayType === 'days' && weekDay) {
                    const { month, textDay, weekNumber } = weekDay;
                    if (!textDay || !weekNumber || !month) return;
                    return `${WEEK_NUMBER[weekNumber]} ${TEXT_DAY[textDay]} del mese di ${MONTH[month]}${
                        allYears ? '' : ` ${weekDay.year ?? ''}`
                    }`;
                }
                break;

            default:
                return null;
        }
    }, [validityPeriod.slots, index, allYears]);

    return labelValue ? <label className={`lightBlue ${classes.selectLabel}`}>{labelValue}</label> : null;
};

export default SelectLabel;

