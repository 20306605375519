import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Tariff } from 'models';

export interface TariffState {
    selectedTariff: Tariff | undefined;
    selectedRule: any | undefined;
    isCreateEditModalOpen: boolean;
    isCreatingRule: boolean;
    isEditingRule: boolean;
    isTariffSimulator: boolean;
}

const name = 'tariffs';
const initialState: TariffState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const tariffActions = { ...slice.actions };
export const tariffReducer = slice.reducer;

function createInitialState(): TariffState {
    return {
        selectedTariff: undefined,
        selectedRule: undefined,
        isCreateEditModalOpen: false,
        isCreatingRule: false,
        isEditingRule: false,
        isTariffSimulator: false
    };
}

function createReducers() {
    return {
        setSelectedTariff,
        setSelectedTariffRule,
        toggleCreateEditModalOpen,
        setIsCreatingRule,
        setIsEditingRule,
        resetSlice,
        setIsTariffSimulator
    };

    function setSelectedTariff(state: TariffState, action: PayloadAction<Tariff | undefined>) {
        state.selectedTariff = action.payload;
    }

    function setSelectedTariffRule(state: TariffState, action: PayloadAction<any | undefined>) {
        state.selectedRule = action.payload;
    }

    function toggleCreateEditModalOpen(state: TariffState) {
        state.isCreateEditModalOpen = !state.isCreateEditModalOpen;
    }

    function setIsCreatingRule(state: TariffState, action: PayloadAction<boolean>) {
        state.isCreatingRule = action.payload;
    }

    function setIsEditingRule(state: TariffState, action: PayloadAction<boolean>) {
        state.isEditingRule = action.payload;
    }

    function resetSlice() {
        return createInitialState();
    }

    function setIsTariffSimulator(state: TariffState, action: PayloadAction<boolean>) {
        state.isTariffSimulator = action.payload;
    }
}

