import { FC, useMemo } from 'react';
import classes from './CustomerServiceItem.module.scss';
import { UsersStatus } from 'models';
import { CheckmarkChecked, CircleBlacklist, RadioUnchecked } from 'components/icons';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CustomerServiceStatus, formatDate } from 'helpers';
import moment from 'moment';

type CustomerServiceItemProps = FC<{
    onClick: () => any;
    item: any;
}>;

const CustomerServiceItem: CustomerServiceItemProps = ({ onClick, item }) => {
    const { selectedCustomerService } = useSelector((x: RootState) => x.customerService);
    const isSelected = useMemo(
        () => selectedCustomerService && selectedCustomerService.id === item.id,
        [selectedCustomerService, item.id]
    );

    return (
        <div id={item.id} onClick={onClick} className={`${classes.container} ${isSelected && classes.selected}`}>
            <div className={classes.header}>
                <div className={classes.name}>
                    {item.status === CustomerServiceStatus.completed ? <CheckmarkChecked /> : <RadioUnchecked />}

                    <b className={item.status === CustomerServiceStatus.notRead ? classes.yellow : ''}>
                        {item.User.first_name + ' ' + item.User.last_name}
                    </b>
                </div>
                <div className={classes.status}>
                    {item.User.status === UsersStatus.blacklisted && <CircleBlacklist />}
                    <div className={`small ${classes.date}`}>{formatDate(moment(item.createdAt))}</div>
                </div>
            </div>
            <div className={classes.description}>{item.description}</div>
        </div>
    );
};

export default CustomerServiceItem;

