import { Close } from 'components/icons';
import { Prohibition } from 'components/icons/Prohibition';
import { FC } from 'react';
import classes from './ScheduleChip.module.scss';
import colors from 'styles/modules/colors.module.scss';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatDate } from 'helpers';

type ScheduleChipProps = FC<{
    label: string;
    close: () => void;
}>;

const ScheduleChip: ScheduleChipProps = ({ label, close }) => {
    const { selectedMunicipality } = useSelector((state: RootState) => state.municipalities);

    return (
        <span className={classes.container}>
            <span className={classes.icon}>
                <Prohibition />
            </span>
            <span className={`small ${classes.label}`}>
                {formatDate(moment(label).tz(selectedMunicipality?.timezone || 'Europe/Rome'), true)}
            </span>
            <span className={classes.close} onClick={close}>
                <Close fill={colors.primary100} />
            </span>
        </span>
    );
};

export default ScheduleChip;

