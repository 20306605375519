import { IconButton } from 'components/buttons';
import { ArrowBack, Delete, Edit } from 'components/icons';
import { usePermissionHandler } from 'hook';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, TabTypes, municipalityActions } from 'store';
import colors from 'styles/modules/colors.module.scss';
import classes from './MunicipalityActions.module.scss';
import DeleteMunicipalityModal from './deleteMunicipalityModal/DeleteMunicipalityModal';
import EditMunicipalityModal from './editMunicipalityModal/EditMunicipalityModal';
import ModalEnableMunicipality from './modalEnableMunicipality/ModalEnableMunicipality';

const MunicipalityActions: FC = () => {
    const { selectedMunicipality } = useSelector((x: RootState) => x.municipalities);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const dispatch = useDispatch();
    const hasPermission = usePermissionHandler();

    const onBackHandler = () => dispatch(municipalityActions.setSelectedMunicipality(undefined));

    if (!selectedMunicipality) return <></>;

    return (
        <div className={classes.action}>
            <div className={classes.back} onClick={onBackHandler}>
                <IconButton icon={<ArrowBack />} onClick={onBackHandler} className="tertiary" />
                <p>{selectedMunicipality.name}</p>
            </div>
            <div className={classes.buttons}>
                <ModalEnableMunicipality />
                <IconButton
                    disabled={!hasPermission(TabTypes.municipalitySettings, ['can-edit'])}
                    icon={
                        <Edit
                            fill={
                                !hasPermission(TabTypes.municipalitySettings, ['can-delete'])
                                    ? colors.primary40
                                    : colors.primary100
                            }
                        />
                    }
                    onClick={() => setIsEditOpen(true)}
                    className="tertiary"
                />
                <IconButton
                    disabled={!hasPermission(TabTypes.municipalitySettings, ['can-delete'])}
                    icon={
                        <Delete
                            fill={
                                !hasPermission(TabTypes.municipalitySettings, ['can-delete'])
                                    ? colors.primary40
                                    : colors.primary100
                            }
                        />
                    }
                    onClick={() => setIsDeleteOpen(true)}
                    className="tertiary"
                />
            </div>
            <EditMunicipalityModal isOpen={isEditOpen} setIsOpen={setIsEditOpen} />
            <DeleteMunicipalityModal isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen} />
        </div>
    );
};
export default MunicipalityActions;

