import TextButton from 'components/buttons/textButton/TextButton';
import Divider from 'components/divider/Divider';
import { Delete } from 'components/icons';
import Snackbar from 'components/snackbar/Snackbar';
import { timeStringToMilliseconds } from 'helpers';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, createTariffRuleActions, snackbarActions, tariffActions } from 'store';
import { useGetTariffsByMunicipalityQuery } from 'store/rtk/tariff.service';
import {
    useLazyCreateTariffRuleQuery,
    useLazyDeleteTariffRuleQuery,
    useLazyUpdateTariffRuleQuery
} from 'store/rtk/tariffRule.service';
import classes from './TariffRuleCreation.module.scss';
import FreeParkingTime from './freeParkingTime/FreeParkingTime';
import Info from './info/Info';
import Pricing from './pricing/Pricing';
import RuleDeleteModal from './ruleDeleteModal/RuleDeleteModal';
import SlopeTimeExceedAlertModal from './slopeTimeExceedAlertModal/SlopeTimeExceedAlertModal';
import ValidityPeriod from './validityPeriod/ValidityPeriod';
import Vehicle from './vehicle/Vehicle';
import moment from 'moment';

interface TariffCreation {
    name: string;
    cardinality: number;
    startTime: string;
    endTime: string;
    repeatedPeriodType: any;
    rateType: any;
    freeTime: number;
    always: boolean;
    repeatYears: boolean;
    minPrice: number;
    minTime: number;
    days: string[];
    tariffId: string;
    oldTariffRuleId?: string;
}

interface SlopeCreation {
    type: any;
    price: number;
    time: number;
    maxTime: number;
    maxPrice: number;
    multiplier: boolean;
    startHours: string;
    endHours: string;
    startTime: number;
    endTime: number;
    cardinality: number;
}

interface PeriodTimeCreation {
    type: any;
    startDay?: string;
    endDay?: string;
    textDay?: number;
    weekNumber?: number;
    month?: number;
    day?: number;
    holiday?: boolean;
    beforeHoliday?: boolean;
}

const TariffRuleCreation: FC = () => {
    const { slopes, allYears, alwaysValid, freeTime, minTime, name, type, validityPeriod, vehicleIds, weekly, isValid } =
        useSelector((x: RootState) => x.createTariffRule);
    const { municipality } = useSelector((x: RootState) => x.municipalities);
    const { selectedTariff, selectedRule, isEditingRule, isCreatingRule } = useSelector((x: RootState) => x.tariffs);
    const [createTariffRule] = useLazyCreateTariffRuleQuery();
    const [updateTariffRule] = useLazyUpdateTariffRuleQuery();
    const [deleteTariffRule] = useLazyDeleteTariffRuleQuery();
    const { refetch } = useGetTariffsByMunicipalityQuery({ municipalityId: municipality?.id! });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isExceedingModalOpen, setIsExceedingModalOpen] = useState(false);
    const [createdSlopes, setCreatedSlopes] = useState<SlopeCreation[]>([]);
    const [validityPeriods, setValidityPeriods] = useState<PeriodTimeCreation[]>([]);
    const [tariffRule, setTariffRule] = useState<TariffCreation>({} as TariffCreation);
    const dispatch = useDispatch();

    const checkSlopesExceedingRuleTime = ({
        createdSlopes,
        tariffRule
    }: {
        createdSlopes: SlopeCreation[];
        tariffRule: TariffCreation;
    }) => {
        const ruleStartTimeMs = timeStringToMilliseconds(tariffRule?.startTime);
        const ruleEndTimeMs = timeStringToMilliseconds(tariffRule?.endTime);
        const timeDelta = ruleEndTimeMs - ruleStartTimeMs;

        const isExceedingProportionalSlope = (slope: SlopeCreation) => {
            if (slope.maxTime === 0) return timeDelta < (slope.time * 60_000) / slope.price;
            return timeDelta < slope.maxTime * 60_000;
        };

        const isExceedingStairwaySlope = (slope: SlopeCreation) => {
            const slopeStartTimeMs =
                slope.startTime === 0 ? timeStringToMilliseconds(slope.startHours) : slope.startTime * 60_000;
            const slopeEndTimeMs = slope.endTime === 0 ? timeStringToMilliseconds(slope.endHours) : slope.endTime * 60_000;
            return slopeEndTimeMs - slopeStartTimeMs > timeDelta;
        };

        for (const slope of createdSlopes) {
            if (slope.type === 'proportional' && isExceedingProportionalSlope(slope)) return true;
            if (slope.type === 'stairway' && isExceedingStairwaySlope(slope)) return true;
        }

        return false;
    };

    const onSubmitHandler = async () => {
        dispatch(createTariffRuleActions.setSubmit(true));
        const error = Object.keys(isValid).filter(field => !isValid[field]);
        if (error.length) return;
        const tariffRule: TariffCreation = {
            name,
            cardinality: selectedRule?.cardinality ?? selectedTariff?.TariffRules?.length ?? 0,
            always: alwaysValid,
            rateType: type,
            startTime: validityPeriod?.period?.startTime,
            endTime: validityPeriod?.period?.endTime,
            repeatedPeriodType: validityPeriod?.repetitionType,
            freeTime:
                type === 'slopes' ? +(freeTime?.days ?? 0) * 24 * 60 + +(freeTime?.hours ?? 0) * 60 + +(freeTime?.mins ?? 0) : 0,
            repeatYears: allYears,
            minPrice: type === 'slopes' ? +(minTime?.amount ?? 0) : 0,
            minTime:
                type === 'slopes' ? +(minTime?.days ?? 0) * 24 * 60 + +(minTime?.hours ?? 0) * 60 + +(minTime?.mins ?? 0) : 0,
            days: weekly,
            tariffId: selectedTariff?.id!,
            oldTariffRuleId: selectedRule?.id
        };
        const createdSlopes = slopes?.map<SlopeCreation>((slope: any, index) => {
            return {
                type: slope?.slopeType,
                price: +slope?.amount,
                time: (+slope?.days || 0) * 24 * 60 + (+slope?.hours || 0) * 60 + (+slope?.minutes || 0),
                maxTime: (+slope?.maxDays || 0) * 24 * 60 + (+slope?.maxHours || 0) * 60 + (+slope?.maxMinutes || 0),
                maxPrice: +slope?.price || 0,
                multiplier: slope?.multiplier,
                startHours: slope?.fromHour,
                endHours: slope?.toHour,
                startTime: (+slope?.fromDays || 0) * 24 * 60 + (+slope?.fromHours || 0) * 60 + (+slope?.fromMinutes || 0),
                endTime: (+slope?.toDays || 0) * 24 * 60 + (+slope?.toHours || 0) * 60 + (+slope?.toMinutes || 0),
                cardinality: index
            };
        });
        const validityPeriods = validityPeriod.slots.map<PeriodTimeCreation>(slot => {
            const year = allYears
                ? undefined
                : slot.type === 'preset'
                ? slot.preset.year
                : slot.dayType === 'days'
                ? slot.weekDay.year
                : slot.day.year;

            const startDate = moment(slot.period.startDate)
                .tz(municipality?.timezone ?? 'Europe/Rome', true)
                .format('YYYY-MM-DD HH:mm:ss.SSS Z');
            const endDate = moment(slot.period.endDate)
                .tz(municipality?.timezone ?? 'Europe/Rome', true)
                .format('YYYY-MM-DD HH:mm:ss.SSS Z');

            return {
                type: slot.type,
                startDay: slot.period.startDate ? startDate : undefined,
                endDay: slot.period.endDate ? endDate : undefined,
                textDay: slot.weekDay.textDay,
                weekNumber: slot.weekDay.weekNumber,
                month: slot.dayType === 'days' ? slot.weekDay.month : slot.day.month,
                year: year,
                day: slot.day.day,
                holiday: slot.preset.holiday,
                beforeHoliday: slot.preset.beforeHoliday
            };
        });

        const isSlopeTimeExceeding = checkSlopesExceedingRuleTime({
            createdSlopes: createdSlopes,
            tariffRule: tariffRule
        });
        if (isSlopeTimeExceeding) {
            setIsExceedingModalOpen(true);
            setCreatedSlopes(createdSlopes);
            setValidityPeriods(validityPeriods);
            setTariffRule(tariffRule);
        } else {
            saveRule({
                createdSlopes: createdSlopes,
                validityPeriods: validityPeriods,
                tariffRule: tariffRule
            });
        }
    };

    const saveRule = async ({
        createdSlopes,
        validityPeriods,
        tariffRule
    }: {
        createdSlopes: SlopeCreation[];
        validityPeriods: PeriodTimeCreation[];
        tariffRule: TariffCreation;
    }) => {
        let res;
        if (isCreatingRule) {
            res = await createTariffRule({
                slopes: type === 'slopes' ? createdSlopes : [],
                tariffRule,
                validityPeriods: alwaysValid ? [] : validityPeriods,
                vehicleTypeIds: vehicleIds
            });
        } else {
            res = await updateTariffRule({
                slopes: type === 'slopes' ? createdSlopes : [],
                tariffRule,
                validityPeriods: alwaysValid ? [] : validityPeriods,
                vehicleTypeIds: vehicleIds
            });
        }
        if (!res.data) return;
        const data = await refetch();
        const updatedTariff = data.data?.find(tariff => tariff.id === selectedTariff?.id);
        dispatch(tariffActions.setSelectedTariff(updatedTariff));
        dispatch(
            snackbarActions.setMessageAndType({
                message: isCreatingRule ? `Regola creata con successo.` : `Regola modificata con successo.`
            })
        );
        dispatch(snackbarActions.setIsOpen(true));
        onCancelHandler();
    };

    const onCancelHandler = () => {
        dispatch(createTariffRuleActions.resetCreation());
        dispatch(tariffActions.setSelectedTariffRule(undefined));
        dispatch(tariffActions.setIsCreatingRule(false));
        dispatch(tariffActions.setIsEditingRule(false));
    };

    const onDeleteHandler = async () => {
        if (!selectedRule) return;
        try {
            const res = await deleteTariffRule({ id: selectedRule.id });
            if (!res.data) return;
            const data = await refetch();
            const updatedTariff = data.data?.find(tariff => tariff.id === selectedTariff?.id);
            dispatch(tariffActions.setSelectedTariff(updatedTariff));
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Regola eliminata con successo.`
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
            onCancelHandler();
        } catch (error) {
            console.error('Error deleting tariff rule:', error);
            dispatch(
                snackbarActions.setMessageAndType({
                    message: `Errore nell'eliminazione della regola.`
                })
            );
            dispatch(snackbarActions.setIsOpen(true));
        }
    };

    const trySubmission = () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        onSubmitHandler();
        setIsSubmitting(false);
    };

    return (
        <div className={classes.tariffRuleContainer}>
            <div className={classes.tariffRuleTitle}>
                <p>
                    <b>{isEditingRule ? 'Modifica Regola' : 'Nuova regola'}</b>
                </p>
                <TextButton
                    type="button"
                    className="secondary"
                    onClick={() => (isEditingRule ? setIsDeleteModalOpen(true) : onCancelHandler())}
                >
                    {isEditingRule ? (
                        <>
                            <Delete />
                            Elimina
                        </>
                    ) : (
                        'Annulla'
                    )}
                </TextButton>
            </div>
            <div className={classes.tariffConfContainer}>
                <Info />
                <Divider customClasses={classes.divider} />
                <ValidityPeriod />
                <Divider customClasses={classes.divider} />
                <Vehicle />
                {type === 'slopes' && (
                    <>
                        <Divider customClasses={classes.divider} />
                        <FreeParkingTime />
                        <Divider customClasses={classes.divider} />
                        <Pricing />
                    </>
                )}
                <Divider customClasses={classes.divider} />
                <div className={classes.save}>
                    <TextButton type="submit" className="primary" onClick={trySubmission}>
                        Salva ed applica
                    </TextButton>
                </div>
            </div>
            <Snackbar />
            <RuleDeleteModal
                isDeleteModalOpen={isDeleteModalOpen}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                onDeleteHandler={onDeleteHandler}
                selectedRuleName={selectedRule?.name}
            />
            <SlopeTimeExceedAlertModal
                isOpen={isExceedingModalOpen}
                setIsOpen={setIsExceedingModalOpen}
                onSubmitHandler={() => saveRule({ createdSlopes, validityPeriods, tariffRule })}
            />
        </div>
    );
};

export default TariffRuleCreation;

