import classes from './Account.module.scss';
import { FC } from 'react';
import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { UserWithVehicles } from 'models';
import { formatDate } from 'helpers';
import moment from 'moment';

type AccountProps = FC<{
    user: UserWithVehicles;
}>;

const Account: AccountProps = ({ user }) => {
    return (
        <div className={classes.accordionContainer}>
            <Accordion title="Account">
                <AccordionTypography>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Numero di telefono</b>
                            </label>
                            <p>{user?.telephoneNumber ?? '-'}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Email</b>
                            </label>
                            <p className={classes.email}>{user?.email ?? '-'}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Nome</b>
                            </label>
                            <p>{user?.firstName}</p>
                        </div>
                        <div>
                            <label className="small">
                                <b>Creazione account</b>
                            </label>
                            <p>{formatDate(moment(user?.createdAt))}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Cognome</b>
                            </label>
                            <p>{user?.lastName}</p>
                        </div>
                        <div>
                            {user?.telephoneNumber ? (
                                <>
                                    <label className="small">
                                        <b>Customer Id</b>
                                    </label>
                                    <p className={classes.customerId}>{user?.customerId}</p>
                                </>
                            ) : (
                                <>
                                    <label className="small">
                                        <b>Elimazione Account</b>
                                    </label>
                                    <p className={classes.customerId}>{formatDate(moment(user?.deletedAt))}</p>
                                </>
                            )}
                        </div>
                    </div>
                </AccordionTypography>
            </Accordion>
        </div>
    );
};

export default Account;

