import Modal from 'components/modal/Modal';
import Snackbar from 'components/snackbar/Snackbar';
import { SetState } from 'helpers';
import { FC } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, municipalityActions, snackbarActions } from 'store';
import { useLazyCreateMunicipalityQuery } from 'store/rtk/municipality.service';
import CreateMunicipalityForm from './createMunicipalityForm/CreateMunicipalityForm';
import { Municipality } from 'models';

type CreateAreaModalProps = FC<{
    isOpen: boolean;
    setIsOpen: SetState<boolean>;
    refetch: () => void;
}>;

const CreateAreaModal: CreateAreaModalProps = ({ isOpen, setIsOpen, refetch }) => {
    const [createMunicipality] = useLazyCreateMunicipalityQuery();
    const dispatch = useDispatch();
    const { municipalities } = useSelector((x: RootState) => x.municipalities);

    const onSubmit: SubmitHandler<FieldValues> = async city => {
        if (!city.percentageFeeCheckbox || isNaN(city.percentageFees)) city.percentageFees = 0;
        if (!city.fixedFeeCheckbox || isNaN(city.fixedFees)) city.fixedFees = 0;

        const alreadyExists = municipalities.find(item => item.name.toLowerCase() === city.name.toLowerCase());
        if (alreadyExists) {
            dispatch(snackbarActions.setMessageAndType({ message: `La città inserita è già esistente.`, type: 'error' }));
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }

        city.percentageFees = +city.percentageFees;
        city.fixedFees = +city.fixedFees;
        delete city.percentageFeeCheckbox;
        delete city.fixedFeeCheckbox;
        try {
            const { data, error } = await createMunicipality(city as Municipality);
            if (error && (error as any).data?.message === 'municipality-already-exists-error') {
                dispatch(
                    snackbarActions.setMessageAndType({
                        message: `Esiste già un comune con lo stesso codice commessa.`,
                        type: 'error'
                    })
                );
                dispatch(snackbarActions.setIsOpen(true));
                return;
            }
            if (!data) return;
            dispatch(municipalityActions.addMunicipalities({ ...data, numberAreas: '0', numberTariff: '0' }));
            dispatch(snackbarActions.setMessageAndType({ message: `Città inserita con successo.` }));
            dispatch(snackbarActions.setIsOpen(true));
            setIsOpen(false);
        } catch (err) {
            dispatch(snackbarActions.setMessageAndType({ type: 'error', message: ` Errore nella creazione della città` }));
            dispatch(snackbarActions.setIsOpen(true));
            console.log(err);
        } finally {
            refetch();
        }
    };

    return (
        <>
            <Modal title="Aggiungi città" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
                <CreateMunicipalityForm onSubmit={onSubmit} setIsOpen={setIsOpen} />
            </Modal>
            <Snackbar />
        </>
    );
};

export default CreateAreaModal;

