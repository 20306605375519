import Modal from 'components/modal/Modal';
import Snackbar from 'components/snackbar/Snackbar';
import { NewTariff } from 'helpers';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, municipalityActions, snackbarActions, tariffActions } from 'store';
import { useGetAllTariffsQuery, useLazyCreateTariffQuery } from 'store/rtk/tariff.service';
import CreateEditTariffModalForm from 'components/createEditTariffModalForm/CreateEditTariffModalForm';

type CreateTariffModalProps = FC<{
    refetch: () => void;
}>;

const CreateTariffModal: CreateTariffModalProps = ({ refetch }) => {
    const { selectedMunicipality } = useSelector((x: RootState) => x.municipalities);
    const { isCreateEditModalOpen } = useSelector((x: RootState) => x.tariffs);
    const dispatch = useDispatch();
    const [createTariff] = useLazyCreateTariffQuery();
    const { municipality } = useSelector((x: RootState) => x.municipalities);
    const { refetch: refetchTariff } = useGetAllTariffsQuery();
    const onCreateEditHandler = () => dispatch(tariffActions.toggleCreateEditModalOpen());

    const onSubmitHandler = async (formFields: NewTariff) => {
        if (!formFields) return;
        const alreadyExists = municipality?.Tariffs.find(
            item => item.name.toLowerCase() === formFields.name?.toLowerCase() && formFields.name.toLowerCase()
        );
        if (alreadyExists) {
            dispatch(
                snackbarActions.setMessageAndType({ message: `Esiste già una tariffa con il nome inserito.`, type: 'error' })
            );
            dispatch(snackbarActions.setIsOpen(true));
            return;
        }
        if (!selectedMunicipality) return;
        try {
            if (!formFields.name || formFields.slopeReprise === undefined) return;
            const { data } = await createTariff({
                name: formFields.name,
                slopeReprise: formFields.slopeReprise,
                municipalityId: selectedMunicipality?.id
            });
            if (!data) return;
            dispatch(municipalityActions.addTariff(data));
            dispatch(snackbarActions.setMessageAndType({ message: `Tariffa creata con successo.` }));
            dispatch(snackbarActions.setIsOpen(true));
            refetch();
            refetchTariff();
            onCreateEditHandler();
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Modal title="Aggiungi Tariffa" isOpen={isCreateEditModalOpen} handleClose={onCreateEditHandler}>
                <CreateEditTariffModalForm onSubmit={onSubmitHandler} />
            </Modal>
            <Snackbar />
        </>
    );
};

export default CreateTariffModal;

