import classes from './Vehicles.module.scss';
import Accordion from 'components/accordion/Accordion';
import AccordionTypography from 'components/accordionTypography/AccordionTypography';
import { formatDate } from 'helpers';
import { UserDetailVehicle } from 'models';
import moment from 'moment';
import { FC, useMemo } from 'react';

type VehiclesProps = FC<{
    vehicles: UserDetailVehicle[];
}>;

const LABELS: Record<string, string> = {
    car: 'Autoveicolo',
    motorbike: 'Motoveicolo',
    camper: 'Camper',
    bus: 'Bus',
    electricAuto: 'Auto elettrica',
    hybrid: 'Auto ibrida',
    gplAuto: 'Auto GPL'
};

const Vehicles: VehiclesProps = ({ vehicles }) => {
    const mappedTypography = useMemo(
        () =>
            vehicles?.map(vehicle => (
                <AccordionTypography>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Targa</b>
                            </label>
                            <p>{vehicle?.plate}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Tipo</b>
                            </label>
                            <p>{LABELS[vehicle?.type]}</p>
                        </div>
                    </div>
                    <div className={classes.field}>
                        <div>
                            <label className="small">
                                <b>Associato il</b>
                            </label>
                            <p>{vehicle.createdAt && formatDate(moment(vehicle.createdAt))}</p>
                        </div>
                    </div>
                </AccordionTypography>
            )),
        [vehicles]
    );

    if (!vehicles || vehicles[0].plate === null) return <></>;

    return (
        <div className={classes.accordionContainer}>
            <Accordion title="Veicoli">{mappedTypography}</Accordion>
        </div>
    );
};

export default Vehicles;

